import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AppConfig,
  APP_CONFIG,
  AuthzConfig,
  AUTHZ_CONFIG,
} from '@sidkik/global';
import { ButtonState, ErrorService } from '@sidkik/ui';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import {
  AuthProcessService,
  AuthProvider,
} from '../../services/auth-process.service';
import { LoginAttemptEvent } from './pure-login.component';
import { AuthzFacade } from '../../+state/authz.facade';

@Component({
  selector: 'sidkik-login',
  template: `
    <sidkik-pure-login
      [logoUrl]="logoUrl"
      [registrationEnabled]="registrationEnabled"
      (loginClicked)="login($event)"
      [state]="state$ | async"
      [allowThirdPartyProviders]="allowThirdPartyProviders"
      [source]="source"
    >
    </sidkik-pure-login>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  @Input() logoUrl = this.appConfig.branding?.logo;
  @Output() loginButtonClicked: EventEmitter<void> = new EventEmitter<void>();

  state$: BehaviorSubject<ButtonState> = new BehaviorSubject<ButtonState>(
    ButtonState.ready
  );
  registrationEnabled = true;
  authProviders = AuthProvider;
  redirectUrl?: string;
  source?: string;
  allowThirdPartyProviders = false;

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    @Inject(AUTHZ_CONFIG) readonly authzConfig: AuthzConfig,
    @Inject('ADMIN_TOOL') readonly isAdminTool: boolean,
    private authProcess: AuthProcessService,
    private route: ActivatedRoute,
    private router: Router,
    private errorService: ErrorService,
    private authzFacade: AuthzFacade
  ) {
    this.allowThirdPartyProviders = authzConfig.thirdPartyProvidersAllowed;
    if (isAdminTool) {
      this.registrationEnabled = false; // TODO: add registration enabled to app config
    }
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      this.redirectUrl = params['redirectUrl'];
      if (this.redirectUrl && this.redirectUrl.startsWith('login')) {
        this.redirectUrl = '/';
      }

      this.source = params['source'];
      if (this.redirectUrl) {
        this.authProcess.setRedirectUrl(this.redirectUrl);
      } else {
        this.authProcess.setRedirectUrl('/');
      }
    });
    this.authzFacade.me$
      .pipe(
        filter((me) => me !== undefined && me !== null),
        take(1)
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe((me) => {
        if (me) {
          this.router.navigate([this.redirectUrl ?? '/']);
        }
      });
  }

  async login(loginAttempt: LoginAttemptEvent) {
    // Emit event for button click
    this.loginButtonClicked.emit();
    // const resp = await this.authProcess.resetPassword('admin@sidkik.com');
    this.state$.next(ButtonState.processing);
    try {
      await this.authProcess.signInWith(this.authProviders.EmailAndPassword, {
        email: loginAttempt.email,
        password: loginAttempt.password ?? '',
      });
    } catch (err) {
      this.errorService.handleError(err);
    }
    this.state$.next(ButtonState.ready);
  }
}
