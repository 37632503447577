import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import {
  AppConfig,
  APP_CONFIG,
  HTTPTraceHeader,
  TraceService,
  SpanTypes,
  AutomationStats,
  AutomationState,
  GlobalSessionMetricsResponse,
  FirstSourceFirstMediumByDatesResponse,
  FirstSourceFirstMediumAggregatedForRangeResponse,
  ProductPerformanceResponse,
  RevenueByDayResponse,
  CouponPerformanceByDateRangeResponse,
  StickySessionResponse,
  LandingPageResponse,
  PagePerformanceResponse,
  PeriodLandingPageResponse,
  PeriodPagePerformanceResponse,
  CustomerBreakoutAllTimeTotalsResponse,
} from '@sidkik/global';
import { Observable, catchError, tap, throwError } from 'rxjs';

export interface QueryAPI {
  getCampaignRecipientsCount(
    campaignId: string,
    lists: string[],
    trace?: HTTPTraceHeader
  ): Observable<CampaignRecipientsCount>;
  getListsRecipientCount(trace?: HTTPTraceHeader): Observable<any>;
  getAutomationStats(
    automationIds: string[],
    trace?: HTTPTraceHeader
  ): Observable<AutomationStats[]>;
  getAutomationState(
    automationId: string,
    trace?: HTTPTraceHeader
  ): Observable<AutomationState>;
  getGlobalSessionMetrics(
    startDate: string,
    endDate: string,
    trace?: HTTPTraceHeader
  ): Observable<GlobalSessionMetricsResponse>;
  getFirstSourceFirstMediumByDates(
    startDate: string,
    endDate: string,
    trace?: HTTPTraceHeader
  ): Observable<FirstSourceFirstMediumByDatesResponse>;
  getFirstSourceFirstMediumAggregatedForRange(
    startDate: string,
    endDate: string,
    trace?: HTTPTraceHeader
  ): Observable<FirstSourceFirstMediumAggregatedForRangeResponse>;
  getCouponPerformanceByDateRange(
    startDate: string,
    endDate: string,
    trace?: HTTPTraceHeader
  ): Observable<CouponPerformanceByDateRangeResponse>;
  getRevenueByDay(
    startDate: string,
    endDate: string,
    trace?: HTTPTraceHeader
  ): Observable<RevenueByDayResponse>;
  getProductPerformanceForRange(
    startDate: string,
    endDate: string,
    trace?: HTTPTraceHeader
  ): Observable<ProductPerformanceResponse>;
  getPagePerformance(
    startDate: string,
    endDate: string,
    trace?: HTTPTraceHeader
  ): Observable<PagePerformanceResponse>;
  getLandingPage(
    startDate: string,
    endDate: string,
    trace?: HTTPTraceHeader
  ): Observable<LandingPageResponse>;
  getStickySession(
    startDate: string,
    endDate: string,
    trace?: HTTPTraceHeader
  ): Observable<StickySessionResponse>;
  getPeriodPagePerformance(
    startDate: string,
    endDate: string,
    trace?: HTTPTraceHeader
  ): Observable<PeriodPagePerformanceResponse>;
  getPeriodLandingPage(
    startDate: string,
    endDate: string,
    trace?: HTTPTraceHeader
  ): Observable<PeriodLandingPageResponse>;
  getCustomerBreakoutAllTimeTotals(
    trace?: HTTPTraceHeader
  ): Observable<CustomerBreakoutAllTimeTotalsResponse>;
}

export interface CampaignRecipientsCount {
  campaignId: string;
  count: number;
}

@Injectable({
  providedIn: 'root',
})
export class QueryService implements QueryAPI {
  constructor(
    @Inject(APP_CONFIG) readonly tenantConfig: AppConfig,
    private readonly http: HttpClient,
    private readonly traceService: TraceService
  ) {}

  timeoutMs = 20000;

  retryConfig = {
    retries: 1,
    backoffMs: 1000,
    retryDelayMs: 1000,
    tooBusyRetries: 2,
    checksumRetries: 2,
  };

  private processHeaders(trace?: HTTPTraceHeader): HttpHeaders {
    let headers = new HttpHeaders({
      'ngsw-bypass': 'bypass',
      'Cache-Control': 'no-cache',
    });

    if (trace) {
      headers = new HttpHeaders({
        Traceparent: trace?.traceparent ?? '',
        Tracestate: trace?.tracestate ?? '',
        'ngsw-bypass': 'bypass',
        'Cache-Control': 'no-cache',
        'Access-Control-Expose-Headers':
          'Traceparent,TraceState,Orig-Tracestate,Orig-Traceparent,X-Tp', // expose the trace headers in response
      });
    }
    return headers;
  }

  getCustomerBreakoutAllTimeTotals(
    trace?: HTTPTraceHeader
  ): Observable<CustomerBreakoutAllTimeTotalsResponse> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(
        SpanTypes.queryGetCustomerBreakoutAllTimeTotals
      );
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.queryGetCustomerBreakoutAllTimeTotals
      );
      internalTrace = true;
    }
    return this.http
      .post<CustomerBreakoutAllTimeTotalsResponse>(
        `${this.tenantConfig.api.endpoint}/query/customers/breakout-all-time-totals`,
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.queryGetCustomerBreakoutAllTimeTotals,
              err
            );
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.queryGetCustomerBreakoutAllTimeTotals
            );
        })
      );
  }

  getPeriodPagePerformance(
    startDate: string,
    endDate: string,
    trace?: HTTPTraceHeader
  ): Observable<PeriodPagePerformanceResponse> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.queryGetPeriodPagePerformance);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.queryGetPeriodPagePerformance
      );
      internalTrace = true;
    }
    return this.http
      .post<PeriodPagePerformanceResponse>(
        `${this.tenantConfig.api.endpoint}/query/sessions/page-performance-period`,
        { startDate, endDate },
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.queryGetPeriodPagePerformance,
              err
            );
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.queryGetPeriodPagePerformance);
        })
      );
  }

  getPagePerformance(
    startDate: string,
    endDate: string,
    trace?: HTTPTraceHeader
  ): Observable<PagePerformanceResponse> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.queryGetPagePerformance);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.queryGetPagePerformance
      );
      internalTrace = true;
    }
    return this.http
      .post<PagePerformanceResponse>(
        `${this.tenantConfig.api.endpoint}/query/sessions/page-performance`,
        { startDate, endDate },
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.queryGetPagePerformance, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.queryGetPagePerformance);
        })
      );
  }

  getPeriodLandingPage(
    startDate: string,
    endDate: string,
    trace?: HTTPTraceHeader
  ): Observable<PeriodLandingPageResponse> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.queryGetPeriodLandingPage);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.queryGetPeriodLandingPage
      );
      internalTrace = true;
    }
    return this.http
      .post<PeriodLandingPageResponse>(
        `${this.tenantConfig.api.endpoint}/query/sessions/landing-page-period`,
        { startDate, endDate },
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.queryGetPeriodLandingPage, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.queryGetPeriodLandingPage);
        })
      );
  }

  getLandingPage(
    startDate: string,
    endDate: string,
    trace?: HTTPTraceHeader
  ): Observable<LandingPageResponse> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.queryGetLandingPage);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.queryGetLandingPage
      );
      internalTrace = true;
    }
    return this.http
      .post<LandingPageResponse>(
        `${this.tenantConfig.api.endpoint}/query/sessions/landing-page`,
        { startDate, endDate },
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.queryGetLandingPage, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.queryGetLandingPage);
        })
      );
  }

  getStickySession(
    startDate: string,
    endDate: string,
    trace?: HTTPTraceHeader
  ): Observable<StickySessionResponse> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.queryGetStickySession);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.queryGetStickySession
      );
      internalTrace = true;
    }
    return this.http
      .post<StickySessionResponse>(
        `${this.tenantConfig.api.endpoint}/query/sessions/sticky-session`,
        { startDate, endDate },
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.queryGetStickySession, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.queryGetStickySession);
        })
      );
  }

  getCouponPerformanceByDateRange(
    startDate: string,
    endDate: string,
    trace?: HTTPTraceHeader
  ): Observable<CouponPerformanceByDateRangeResponse> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(
        SpanTypes.queryGetCouponPerformanceByDateRange
      );
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.queryGetCouponPerformanceByDateRange
      );
      internalTrace = true;
    }
    return this.http
      .post<CouponPerformanceByDateRangeResponse>(
        `${this.tenantConfig.api.endpoint}/query/ecommerce/coupon-performance-by-date-range`,
        { startDate, endDate },
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.queryGetCouponPerformanceByDateRange,
              err
            );
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.queryGetCouponPerformanceByDateRange
            );
        })
      );
  }

  getRevenueByDay(
    startDate: string,
    endDate: string,
    trace?: HTTPTraceHeader
  ): Observable<RevenueByDayResponse> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.queryGetRevenueByDay);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.queryGetRevenueByDay
      );
      internalTrace = true;
    }
    return this.http
      .post<RevenueByDayResponse>(
        `${this.tenantConfig.api.endpoint}/query/ecommerce/revenue-by-day`,
        { startDate, endDate },
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.queryGetRevenueByDay, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.queryGetRevenueByDay);
        })
      );
  }

  getProductPerformanceForRange(
    startDate: string,
    endDate: string,
    trace?: HTTPTraceHeader
  ): Observable<ProductPerformanceResponse> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.queryGetProductPerformanceForRange);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.queryGetProductPerformanceForRange
      );
      internalTrace = true;
    }
    console.log('getProductPerformanceForRange', startDate, endDate);
    return this.http
      .post<ProductPerformanceResponse>(
        `${this.tenantConfig.api.endpoint}/query/ecommerce/product-performance`,
        { startDate, endDate },
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.queryGetProductPerformanceForRange,
              err
            );
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.queryGetProductPerformanceForRange
            );
        })
      );
  }

  getFirstSourceFirstMediumAggregatedForRange(
    startDate: string,
    endDate: string,
    trace?: HTTPTraceHeader
  ): Observable<FirstSourceFirstMediumAggregatedForRangeResponse> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(
        SpanTypes.queryGetFirstSourceFirstMediumAggregatedForRange
      );
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.queryGetFirstSourceFirstMediumAggregatedForRange
      );
      internalTrace = true;
    }
    return this.http
      .post<FirstSourceFirstMediumAggregatedForRangeResponse>(
        `${this.tenantConfig.api.endpoint}/query/sessions/first-source-first-medium-aggregated-for-range`,
        { startDate, endDate },
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.queryGetFirstSourceFirstMediumAggregatedForRange,
              err
            );
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.queryGetFirstSourceFirstMediumAggregatedForRange
            );
        })
      );
  }

  getFirstSourceFirstMediumByDates(
    startDate: string,
    endDate: string,
    trace?: HTTPTraceHeader
  ): Observable<FirstSourceFirstMediumByDatesResponse> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(
        SpanTypes.queryGetFirstSourceFirstMediumByDates
      );
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.queryGetFirstSourceFirstMediumByDates
      );
      internalTrace = true;
    }
    return this.http
      .post<FirstSourceFirstMediumByDatesResponse>(
        `${this.tenantConfig.api.endpoint}/query/sessions/first-source-first-medium-by-dates`,
        { startDate, endDate },
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.queryGetFirstSourceFirstMediumByDates,
              err
            );
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.queryGetFirstSourceFirstMediumByDates
            );
        })
      );
  }

  getGlobalSessionMetrics(
    startDate: string,
    endDate: string,
    trace?: HTTPTraceHeader
  ): Observable<GlobalSessionMetricsResponse> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.queryGetGlobalSessionMetrics);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.queryGetGlobalSessionMetrics
      );
      internalTrace = true;
    }
    return this.http
      .post<GlobalSessionMetricsResponse>(
        `${this.tenantConfig.api.endpoint}/query/sessions/global`,
        {
          startDate,
          endDate,
        },
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.queryGetGlobalSessionMetrics,
              err
            );
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.queryGetGlobalSessionMetrics);
        })
      );
  }

  getAutomationState(
    automationId: string,
    trace?: HTTPTraceHeader
  ): Observable<AutomationState> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.queryGetAutomationState);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.queryGetAutomationState
      );
      internalTrace = true;
    }
    return this.http
      .get<AutomationState>(
        `${this.tenantConfig.api.endpoint}/query/automations/${automationId}/state`,
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.queryGetAutomationState, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.queryGetAutomationState);
        })
      );
  }

  getAutomationStats(
    automationIds: string[],
    trace?: HTTPTraceHeader
  ): Observable<AutomationStats[]> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.queryGetAutomationStats);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.queryGetAutomationStats
      );
      internalTrace = true;
    }
    return this.http
      .post<AutomationStats[]>(
        `${this.tenantConfig.api.endpoint}/query/automations/stats`,
        {
          automationIds,
        },
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.queryGetAutomationStats, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.queryGetAutomationStats);
        })
      );
  }

  getCampaignRecipientsCount(
    campaignId: string,
    lists: string[],
    trace?: HTTPTraceHeader
  ): Observable<CampaignRecipientsCount> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.queryGetCampaignRecipientsCount);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.queryGetCampaignRecipientsCount
      );
      internalTrace = true;
    }
    return this.http
      .post<CampaignRecipientsCount>(
        `${this.tenantConfig.api.endpoint}/query/campaigns/${campaignId}/recipients`,
        {
          campaignId,
          lists,
        },
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.queryGetCampaignRecipientsCount,
              err
            );
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.queryGetCampaignRecipientsCount
            );
        })
      );
  }

  getListsRecipientCount(trace?: HTTPTraceHeader | undefined): Observable<any> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.queryGetListsRecipientCount);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.queryGetListsRecipientCount
      );
      internalTrace = true;
    }
    return this.http
      .post<any>(`${this.tenantConfig.api.endpoint}/query/lists/recipients`, {
        headers: this.processHeaders(trace),
      })
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.queryGetListsRecipientCount,
              err
            );
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.queryGetListsRecipientCount);
        })
      );
  }
}
