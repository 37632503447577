import { Meta } from '../meta';
import { StorageProperties, Storage } from '../storage';
import { EntityType } from '@sidkik/global';
import { Trace } from '../trace';

export interface ProvisionalCustomerData {
  tenant: string;
  provider: string;
  authenticationTenant: string;
  authenticationId: string;
  displayName: string;
  email: string;
}

export interface ProvisionalCustomerProperties extends StorageProperties {
  data: ProvisionalCustomerData;
  meta: Meta;
  id: string;
  trace?: Trace;
}

export class ProvisionalCustomer
  extends Storage
  implements ProvisionalCustomerProperties
{
  public override data!: ProvisionalCustomerData;
  constructor(options?: ProvisionalCustomerProperties, user?: string) {
    super(options, user, EntityType.ProvisionalCustomer);
    this.update(options?.data);
  }

  public update(data?: ProvisionalCustomerData): void {
    this.data = { ...data } as unknown as ProvisionalCustomerData;
  }

  public addTrace(trace?: Trace): void {
    this.trace = { ...trace };
  }
}
