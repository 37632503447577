import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AppConfig,
  APP_CONFIG,
  AuthzConfig,
  AUTHZ_CONFIG,
} from '@sidkik/global';
import { ButtonState, ErrorService } from '@sidkik/ui';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  AuthProcessService,
  AuthProvider,
} from '../../services/auth-process.service';
import { ForgotPasswordAttemptEvent } from './pure-forgot-password.component';
import { AutoDestroy } from '@sidkik/shared';

@Component({
  selector: 'sidkik-forgot-password',
  template: `
    <sidkik-pure-forgot-password
      [logoUrl]="logoUrl"
      (forgotClicked)="forgot($event)"
      [state]="state$ | async"
    >
    </sidkik-pure-forgot-password>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordComponent {
  @Input() logoUrl = this.appConfig.branding?.logo;
  // @Input() providers: AuthProvider[] | AuthProvider = AuthProvider.ALL; //  google, facebook, twitter, github as array or all as one single string
  // @Input() registrationEnabled = true;
  // @Input() resetPasswordEnabled = true;
  @Input() messageOnAuthSuccess = '';
  @Input() messageOnAuthError = '';

  @AutoDestroy()
  state$: BehaviorSubject<ButtonState> = new BehaviorSubject<ButtonState>(
    ButtonState.ready
  );
  registrationEnabled = true;
  authProviders = AuthProvider;
  redirectUrl?: string;

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    @Inject(AUTHZ_CONFIG) readonly authzConfig: AuthzConfig,
    private authProcess: AuthProcessService,
    private route: ActivatedRoute,
    private errorService: ErrorService
  ) {
    this.registrationEnabled = true; // TODO: add registration enabled to app config
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      this.redirectUrl = params['redirectUrl'];
      if (this.redirectUrl && this.redirectUrl.startsWith('login')) {
        this.redirectUrl = '/';
      }

      if (this.redirectUrl) this.authProcess.setRedirectUrl(this.redirectUrl);
    });
  }

  async forgot(forgetAttempt: ForgotPasswordAttemptEvent) {
    this.state$.next(ButtonState.processing);

    try {
      await this.authProcess.resetPassword(forgetAttempt.email);
    } catch (err) {
      this.errorService.handleError(err);
    }
    this.state$.next(ButtonState.ready);
  }
}
