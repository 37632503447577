import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { delay, map, tap, withLatestFrom, timeout } from 'rxjs/operators';
import { timer } from 'rxjs';
import * as AuthzActions from './authz.actions';
import { CustomerProperties, DbService } from '@sidkik/db';
import {
  EntityType,
  loadMeMaxRetries,
  SpanTypes,
  TraceService,
} from '@sidkik/global';
import { of } from 'rxjs';
import { AuthzFacade } from './authz.facade';
import { User } from '@angular/fire/auth';
import { pessimisticUpdate } from '@ngrx/router-store/data-persistence';
import { CustomerService } from '@sidkik/sidkik-api';

@Injectable()
export class AuthzEffects {
  delay = 2500;

  logoutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthzActions.logoutSuccess),
        tap(() => setTimeout(() => this.router.navigate(['/']), 1000))
      ),
    { dispatch: false }
  );

  loadMeFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthzActions.loadMeFailure),
      delay(this.delay),
      map((a: any) => AuthzActions.loadMe({ attempt: a.attempt }))
    )
  );

  loadMe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthzActions.loadMe),
      withLatestFrom(this.authzFacade.userId$, this.authzFacade.user$),
      fetch({
        id: (
          a: ReturnType<typeof AuthzActions.loadMe>,
          userId: string | null,
          user: Partial<User> | null
        ) => a.type + userId + user?.uid,
        run: (
          a: ReturnType<typeof AuthzActions.loadMe>,
          userId: string | null,
          user: Partial<User> | null
        ) => {
          if (user && user.uid?.startsWith('u_')) {
            return of(null).pipe(map(() => AuthzActions.noop()));
          }
          if (!userId) {
            logger.error(
              'authz:authz.effects.ts:loadMe',
              'userId is not set yet'
            );
            throw new Error('userId is not set yet');
          }
          return this.dbService
            .getEntity<CustomerProperties>(EntityType.Customer, userId)
            .pipe(
              map((doc: CustomerProperties | undefined) => {
                if (!doc || !doc?.integrations?.stripe?.id) {
                  logger.error(
                    'authz:authz.effects.ts:loadMe',
                    'no stripe id yet -- attempt:' +
                      (a.attempt ?? 0) +
                      ' of ' +
                      loadMeMaxRetries
                  );
                  // need stripe id to purchase
                  throw new Error(
                    'user is missing stripe id - attempt:' +
                      (a.attempt ?? 0) +
                      ' of ' +
                      loadMeMaxRetries
                  );
                }
                logger.trace('authz:authz.effects.ts:loadMe', 'got me', doc);
                return AuthzActions.loadMeSuccess({
                  me: doc,
                });
              })
            );
        },
        onError: (a: ReturnType<typeof AuthzActions.loadMe>, error) => {
          if (a.attempt && a.attempt >= loadMeMaxRetries) {
            logger.error(
              'authz:authz.effects.ts:loadMe',
              'unable to load me with max retries'
            );
            return null;
          }
          return AuthzActions.loadMeFailure({
            error: error.message,
            attempt: a.attempt ? a.attempt + 1 : 1,
          });
        },
      })
    )
  );

  requestProvisionalCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthzActions.requestProvisionalCustomer),
      pessimisticUpdate({
        run: (
          a: ReturnType<typeof AuthzActions.requestProvisionalCustomer>
        ) => {
          logger.info(
            'authz:authz.effects.ts:requestProvisionalCustomer',
            'writing provisional customer'
          );
          return this.customerAPI
            .processProvisionalCustomer(a.provisionalCustomer)
            .pipe(
              timeout({
                each: 15000, // 15 second timeout
                with: () => {
                  logger.warn(
                    'authz:authz.effects.ts:requestProvisionalCustomer',
                    'Operation timed out at effect level'
                  );
                  // If we timeout at this level, we'll treat it as a permission error
                  // and retry like we do with other errors
                  return timer(500).pipe(
                    map(() =>
                      AuthzActions.requestProvisionalCustomer({
                        provisionalCustomer: a.provisionalCustomer,
                        attempt: (a.attempt || 0) + 1,
                      })
                    )
                  );
                },
              }),
              map(() =>
                AuthzActions.requestProvisionalCustomerSuccess({
                  provisionalCustomer: a.provisionalCustomer,
                })
              ),
              tap((result) => {
                logger.info(
                  'authz:authz.effects.ts:requestProvisionalCustomer',
                  'success',
                  result
                );
                this.traceService.endSpan(
                  SpanTypes.firebaseCreateProvisionalCustomer
                );
              })
            );
        },
        onError: (
          a: ReturnType<typeof AuthzActions.requestProvisionalCustomer>,
          error
        ) => {
          const isPermissionError =
            error.message?.toLowerCase().includes('permission') ||
            error.message?.toLowerCase().includes('unauthorized');
          const isTimeoutError =
            error.name === 'TimeoutError' ||
            error.message?.toLowerCase().includes('timeout');
          const currentAttempt = a.attempt || 0;
          const maxRetries = 10;

          if (
            (isPermissionError || isTimeoutError) &&
            currentAttempt < maxRetries
          ) {
            const delay = isTimeoutError ? 1000 : 500; // longer delay for timeouts

            logger.warn(
              'authz:authz.effects.ts:requestProvisionalCustomer',
              `${isTimeoutError ? 'Timeout' : 'Permission'} error, retrying (${
                currentAttempt + 1
              }/${maxRetries}) after ${delay}ms delay`,
              error.message
            );

            return timer(delay).pipe(
              map(() =>
                AuthzActions.requestProvisionalCustomer({
                  provisionalCustomer: a.provisionalCustomer,
                  attempt: currentAttempt + 1,
                })
              )
            );
          }

          logger.error(
            'authz:authz.effects.ts:requestProvisionalCustomer',
            'failure',
            error.message
          );
          return AuthzActions.requestProvisionalCustomerFailure({
            error: error.message,
          });
        },
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private router: Router,
    private readonly dbService: DbService,
    private readonly authzFacade: AuthzFacade,
    private readonly traceService: TraceService,
    private readonly customerAPI: CustomerService
  ) {}
}
