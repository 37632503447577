import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AppConfig,
  APP_CONFIG,
  AuthzConfig,
  AUTHZ_CONFIG,
} from '@sidkik/global';
import { ButtonState, ErrorService } from '@sidkik/ui';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  AuthProcessService,
  AuthProvider,
} from '../../services/auth-process.service';
import { RegisterAttemptEvent } from './pure-register.component';
import { AutoDestroy } from '@sidkik/shared';

// import { AuthProvider } from '../../_services/auth-process.service';

@Component({
  selector: 'sidkik-register',
  template: `
    <sidkik-pure-register
      [logoUrl]="logoUrl"
      [registrationEnabled]="registrationEnabled"
      (registerClicked)="register($event)"
      [state]="state$ | async"
      [allowThirdPartyProviders]="allowThirdPartyProviders"
      [source]="source"
    >
    </sidkik-pure-register>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent {
  @Input() logoUrl = this.appConfig.branding?.logo;
  @Output() registerButtonClicked: EventEmitter<void> =
    new EventEmitter<void>();

  @AutoDestroy()
  state$: BehaviorSubject<ButtonState> = new BehaviorSubject<ButtonState>(
    ButtonState.ready
  );
  registrationEnabled = true;
  authProviders = AuthProvider;
  redirectUrl?: string;
  source = '';
  allowThirdPartyProviders = false;

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    @Inject(AUTHZ_CONFIG) readonly authzConfig: AuthzConfig,
    @Inject('ADMIN_TOOL') readonly isAdminTool: boolean,
    private authProcess: AuthProcessService,
    private route: ActivatedRoute,
    private errorService: ErrorService
  ) {
    this.allowThirdPartyProviders = authzConfig.thirdPartyProvidersAllowed;
    if (isAdminTool) {
      this.registrationEnabled = false; // TODO: add registration enabled to app config
    }
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      this.redirectUrl = params['redirectUrl'];
      if (this.redirectUrl && this.redirectUrl.startsWith('login')) {
        this.redirectUrl = '/';
      }

      this.source = params['source'];
      if (this.redirectUrl) {
        this.authProcess.setRedirectUrl(this.redirectUrl);
      } else {
        this.authProcess.setRedirectUrl(
          this.authProcess.getRedirectUrl() ?? '/'
        );
      }
    });
  }

  async register(registerAttempt: RegisterAttemptEvent) {
    // Emit event for button click
    this.registerButtonClicked.emit();
    this.state$.next(ButtonState.processing);
    try {
      await this.authProcess.signUp(
        registerAttempt.name,
        {
          email: registerAttempt.email,
          password: registerAttempt.password ?? '',
        },
        this.source
      );
    } catch (err) {
      this.errorService.handleError(err);
    }
    this.state$.next(ButtonState.ready);
  }
}
