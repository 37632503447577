import { AppConfig } from '@sidkik/global';
import { Document } from '../db.config';
import { EntityType } from '@sidkik/global';
import { Observable } from 'rxjs';

/**
 * Constants for worker method names to avoid using magic strings
 */
export enum WorkerMethods {
  START = 'start',
  INIT = 'init',
  LOAD_BUNDLE = 'loadBundle',
  UPDATE_APP_CHECK_TOKEN = 'updateAppCheckToken',
  SET_USER = 'setUser',
  REQUEST_SYNC = 'requestSync',
  IMMEDIATE_WRITE = 'immediateWrite',
  MONITOR_REMOTE_DOCUMENT = 'monitorRemoteDocument',
  MONITOR_REMOTE_COLLECTION = 'monitorRemoteCollection',
  MONITOR_REMOTE_DOCUMENT_PERPETUALLY = 'monitorRemoteDocumentPerpetually',
  CLOSE_MONITOR_REMOTE_COLLECTION = 'closeMonitorRemoteCollection',
  CLOSE_MONITOR_REMOTE_DOCUMENT_PERPETUALLY = 'closeMonitorRemoteDocumentPerpetually',
  GET_ALL_REMOTE = 'getAllRemote',
  GET_ALL_NESTED_REMOTE = 'getAllNestedRemote',
  GET_REMOTE = 'getRemote',
  GET_NESTED_REMOTE = 'getNestedRemote',
  TIDY_ON_SIGNOUT = 'tidyOnSignout',
  DEBUG_START_OVER = 'debugStartOver',
  PUSH_CHANGES = 'pushChanges',
}

/**
 * Interface for the DB Worker API to be exposed via Comlink
 * This defines the contract between the main thread and the worker
 */
export interface DBWorkerAPI {
  [WorkerMethods.START](msg: {
    appConfig: AppConfig;
    appVersion: string;
    loggerEnabled: any;
    loggerFilter: any;
    mockEnabled: boolean;
  }): Promise<void>;

  [WorkerMethods.LOAD_BUNDLE](msg: {
    bundle: ArrayBuffer;
    namedQuery: string;
  }): Promise<any>;

  [WorkerMethods.INIT](): Promise<void>;

  [WorkerMethods.UPDATE_APP_CHECK_TOKEN](msg: {
    token: { token: string };
  }): Promise<void>;

  [WorkerMethods.SET_USER](msg: { user: string }): Promise<void>;

  [WorkerMethods.REQUEST_SYNC](msg: {
    data: Document;
    parentEntityType: EntityType;
    parentEntityId: string;
  }): Promise<any>;

  [WorkerMethods.IMMEDIATE_WRITE](msg: {
    data: Document;
    parentEntityType: EntityType;
    parentEntityId: string;
  }): Promise<void>;

  [WorkerMethods.MONITOR_REMOTE_DOCUMENT](msg: {
    entityType: EntityType;
    id: string;
    pathToMonitor: string;
    valuesToFind: any[];
    lastUpdate: number;
    updatedField: string;
    waitTime: number;
    parentEntityType?: EntityType;
    parentEntityId?: string;
  }): Promise<any>;

  // Add all other worker operations here
  [WorkerMethods.MONITOR_REMOTE_COLLECTION](msg: {
    pathToMonitor: string;
  }): Promise<void>;

  [WorkerMethods.MONITOR_REMOTE_DOCUMENT_PERPETUALLY](msg: {
    pathToMonitor: string;
    listenerType: string;
  }): Promise<void>;

  [WorkerMethods.CLOSE_MONITOR_REMOTE_COLLECTION](): Promise<void>;

  [WorkerMethods.CLOSE_MONITOR_REMOTE_DOCUMENT_PERPETUALLY](msg: {
    listenerType: string;
  }): Promise<void>;

  [WorkerMethods.GET_ALL_REMOTE](msg: {
    entityType: EntityType;
  }): Promise<any[]>;

  [WorkerMethods.GET_ALL_NESTED_REMOTE](msg: {
    entityType: EntityType;
    parentEntityType: EntityType;
    parentEntityId: string;
    grandParentEntityType?: EntityType;
    grandParentEntityId?: string;
  }): Promise<any[]>;

  [WorkerMethods.GET_REMOTE](msg: {
    entityType: EntityType;
    id: string;
  }): Promise<any>;

  [WorkerMethods.GET_NESTED_REMOTE](msg: {
    entityType: EntityType;
    id: string;
    parentEntityType: EntityType;
    parentEntityId: string;
  }): Promise<any>;

  [WorkerMethods.TIDY_ON_SIGNOUT](): Promise<void>;

  [WorkerMethods.DEBUG_START_OVER](): Promise<void>;

  [WorkerMethods.PUSH_CHANGES](): Promise<void>;
}
