import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromAuthz from './+state/authz.reducer';
import { AuthzEffects } from './+state/authz.effects';
import { AuthzFacade } from './+state/authz.facade';
import { LoginComponent } from './components/login/login.component';
import { provideAuth, connectAuthEmulator } from '@angular/fire/auth';
import { getAuth } from '@angular/fire/auth';
import { getApp } from '@angular/fire/app';
import {
  AppConfig,
  APP_CONFIG,
  authEmulatorURL,
  AuthzConfigToken,
} from '@sidkik/global';
import { ReactiveFormsModule } from '@angular/forms';
import { PureLoginComponent } from './components/login/pure-login.component';
import {
  AuthzConfig,
  AuthzConfigFactory,
  UserProvidedConfigToken,
} from './authz.config';
import { LogoutComponent } from './components/logout/logout.component';
import { PurePasswordResetComponent } from './components/password-reset/pure-password-reset.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { PureForgotPasswordComponent } from './components/forgot-password/pure-forgot-password.component';
import { UIModule } from '@sidkik/ui';
import { ActionComponent } from './components/action/action.component';
import { AuthProcessService } from './services/auth-process.service';
import { PureRegisterComponent } from './components/register/pure-register.component';
import { RegisterComponent } from './components/register/register.component';
import { MeGuard } from './guards/me/me.guard';
import { APIService } from './services/api.service';
import { RouterModule } from '@angular/router';
import { SidkikAPIModule } from '@sidkik/sidkik-api';
const pureComponents = [
  PureLoginComponent,
  PurePasswordResetComponent,
  PureForgotPasswordComponent,
  PureRegisterComponent,
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    StoreModule.forFeature(fromAuthz.AUTHZ_FEATURE_KEY, fromAuthz.authzReducer),
    EffectsModule.forFeature([AuthzEffects]),
    SidkikAPIModule,
    UIModule,
    RouterModule,
  ],
  providers: [AuthzFacade],
  declarations: [
    LoginComponent,
    ...pureComponents,
    LogoutComponent,
    PasswordResetComponent,
    ForgotPasswordComponent,
    ActionComponent,
    RegisterComponent,
  ],
})
export class AuthzModule {
  // inject into constructor to get the service started
  constructor(private apService: AuthProcessService, apiService: APIService) {}

  static forRoot(config: AuthzConfig = {}): ModuleWithProviders<AuthzModule> {
    return {
      ngModule: AuthzModule,
      providers: [
        provideAuth((injector: Injector) => {
          const appConfig: AppConfig = injector.get<AppConfig>(APP_CONFIG);
          const app = getApp();
          const auth = getAuth(app);
          if (
            appConfig.firebase.authTenantId &&
            appConfig.firebase.authTenantId !== ''
          ) {
            logger.debug(
              'authz:module',
              'setting auth tenant id',
              appConfig.firebase.authTenantId
            );
            auth.tenantId = appConfig.firebase.authTenantId;
          }
          if (appConfig.emulator) {
            // eslint-disable-next-line no-restricted-syntax
            logger.debug(
              'authz:module',
              'connecting auth emulator at',
              authEmulatorURL.toString()
            );
            connectAuthEmulator(auth, authEmulatorURL.toString());
          }
          return auth;
        }),
        { provide: UserProvidedConfigToken, useValue: config },
        {
          provide: AuthzConfigToken,
          useFactory: AuthzConfigFactory,
          deps: [UserProvidedConfigToken],
        },
        MeGuard,
      ],
    };
  }
}
