import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Permissions, ProvisionalCustomerProperties } from '@sidkik/db';
import {
  AppConfig,
  APP_CONFIG,
  TraceService,
  HTTPTraceHeader,
  SpanTypes,
  CustomerInviteData,
  Query,
  QueryResults,
  QueryOperator,
} from '@sidkik/global';
import { Observable, catchError, tap, throwError } from 'rxjs';

export interface CustomerAPI {
  processProvisionalCustomer(
    provisionalCustomer: ProvisionalCustomerProperties,
    trace?: HTTPTraceHeader
  ): Observable<void>;
  updatePermissions(
    customerId: string,
    additions: Permissions,
    removals: Permissions,
    requestId: string,
    trace?: HTTPTraceHeader
  ): Observable<void>;
  verifyEmail(
    ec: string,
    trace?: HTTPTraceHeader
  ): Observable<{
    customerId: string;
    message: string;
    success: boolean;
    redirectUrl: string;
  }>;
  verifyDoubleOptIn(
    doi: string,
    trace?: HTTPTraceHeader
  ): Observable<{
    customerId: string;
    message: string;
    success: boolean;
    redirectUrl: string;
  }>;
  verifyUnsubscribe(
    unSub: string,
    trace?: HTTPTraceHeader
  ): Observable<{ customerId: string; message: string; success: boolean }>;
  addTag(
    tag: string,
    customerId: string,
    trace?: HTTPTraceHeader
  ): Observable<void>;
  removeTag(
    tag: string,
    customerId: string,
    trace?: HTTPTraceHeader
  ): Observable<void>;
  addToList(
    list: string,
    customerId: string,
    trace?: HTTPTraceHeader
  ): Observable<void>;
  bulkAddToLists(
    lists: string[],
    customers: string[],
    trace?: HTTPTraceHeader
  ): Observable<void>;
  bulkRemoveFromLists(
    lists: string[],
    customers: string[],
    trace?: HTTPTraceHeader
  ): Observable<void>;
  bulkAddTags(
    tags: string[],
    customers: string[],
    trace?: HTTPTraceHeader
  ): Observable<void>;
  bulkRemoveTags(
    tags: string[],
    customers: string[],
    trace?: HTTPTraceHeader
  ): Observable<void>;
  removeFromList(
    list: string,
    customerId: string,
    trace?: HTTPTraceHeader
  ): Observable<void>;
  invite(invite: CustomerInviteData, trace?: HTTPTraceHeader): Observable<void>;
  query(q: Query, trace?: HTTPTraceHeader): Observable<QueryResults>;
  markDoNotContact(
    customerId: string,
    trace?: HTTPTraceHeader
  ): Observable<void>;
  unmarkDoNotContact(
    customerId: string,
    trace?: HTTPTraceHeader
  ): Observable<void>;
}

@Injectable({
  providedIn: 'root',
})
export class CustomerService implements CustomerAPI {
  constructor(
    @Inject(APP_CONFIG) readonly tenantConfig: AppConfig,
    private readonly http: HttpClient,
    private readonly traceService: TraceService
  ) {}

  private processHeaders(trace?: HTTPTraceHeader): HttpHeaders {
    let headers = new HttpHeaders({
      'ngsw-bypass': 'bypass',
      'Cache-Control': 'no-cache',
    });

    if (trace) {
      headers = new HttpHeaders({
        Traceparent: trace?.traceparent ?? '',
        Tracestate: trace?.tracestate ?? '',
        'ngsw-bypass': 'bypass',
        'Cache-Control': 'no-cache',
        'Access-Control-Expose-Headers':
          'Traceparent,TraceState,Orig-Tracestate,Orig-Traceparent,X-Tp', // expose the trace headers in response
      });
    }
    return headers;
  }

  processProvisionalCustomer(
    provisionalCustomer: ProvisionalCustomerProperties,
    trace?: HTTPTraceHeader
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminProcessProvisionalCustomer);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminProcessProvisionalCustomer
      );
      internalTrace = true;
    }
    return this.http
      .post<void>(
        `${this.tenantConfig.api.endpoint}/admin/customer/process`,
        provisionalCustomer,
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.adminProcessProvisionalCustomer,
              err
            );
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.adminProcessProvisionalCustomer
            );
        })
      );
  }

  query(query: Query, trace?: HTTPTraceHeader): Observable<QueryResults> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.queryCustomer);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.queryCustomer
      );
      internalTrace = true;
    }
    // handle final query modifications before api

    query.criteria.forEach((c) => {
      c.filters.forEach((f) => {
        switch (f.operator) {
          case QueryOperator.IsTrue:
            f.operator = QueryOperator.EQ;
            f.values = ['true'];
            break;
          case QueryOperator.IsFalse:
            f.operator = QueryOperator.NE;
            f.values = ['true'];
            break;
          default:
            break;
        }
      });
    });

    return this.http
      .post<QueryResults>(
        `${this.tenantConfig.api.endpoint}/query/customers`,
        query,
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.queryCustomer, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace && this.traceService.endSpan(SpanTypes.queryCustomer);
        })
      );
  }

  updatePermissions(
    customerId: string,
    additions: Permissions,
    removals: Permissions,
    requestId: string,
    trace?: HTTPTraceHeader
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminUpdatePermissions);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminUpdatePermissions
      );
      internalTrace = true;
    }
    return this.http
      .put<void>(
        `${this.tenantConfig.api.endpoint}/admin/customer/${customerId}/permissions`,
        { additions, removals, requestId },
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminUpdatePermissions, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminUpdatePermissions);
        })
      );
  }

  invite(
    invite: CustomerInviteData,
    trace?: HTTPTraceHeader
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminCustomerInvite);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminCustomerInvite
      );
      internalTrace = true;
    }
    return this.http
      .post<void>(
        `${this.tenantConfig.api.endpoint}/admin/customer/invite`,
        invite,
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminCustomerInvite, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminCustomerInvite);
        })
      );
  }

  verifyEmail(
    ec: string,
    trace?: HTTPTraceHeader
  ): Observable<{
    customerId: string;
    message: string;
    success: boolean;
    redirectUrl: string;
  }> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminECVerify);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminECVerify
      );
      internalTrace = true;
    }
    return this.http
      .get<{
        customerId: string;
        message: string;
        success: boolean;
        redirectUrl: string;
      }>(`${this.tenantConfig.api.endpoint}/admin/ec/${ec}`, {
        headers: this.processHeaders(trace),
      })
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminECVerify, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace && this.traceService.endSpan(SpanTypes.adminECVerify);
        })
      );
  }

  verifyDoubleOptIn(
    doi: string,
    trace?: HTTPTraceHeader
  ): Observable<{
    customerId: string;
    message: string;
    success: boolean;
    redirectUrl: string;
  }> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminDOIVerify);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminDOIVerify
      );
      internalTrace = true;
    }
    return this.http
      .get<{
        customerId: string;
        message: string;
        success: boolean;
        redirectUrl: string;
      }>(`${this.tenantConfig.api.endpoint}/admin/doi/${doi}`, {
        headers: this.processHeaders(trace),
      })
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminDOIVerify, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace && this.traceService.endSpan(SpanTypes.adminDOIVerify);
        })
      );
  }

  verifyUnsubscribe(
    unSub: string,
    trace?: HTTPTraceHeader
  ): Observable<{ customerId: string; message: string; success: boolean }> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminUnsubscribeVerify);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminUnsubscribeVerify
      );
      internalTrace = true;
    }
    return this.http
      .put<{ customerId: string; message: string; success: boolean }>(
        `${this.tenantConfig.api.endpoint}/admin/list/unsubscribe/${unSub}`,
        undefined,
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminUnsubscribeVerify, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminUnsubscribeVerify);
        })
      );
  }

  markDoNotContact(
    customerId: string,
    trace?: HTTPTraceHeader
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminMarkDoNotContact);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminMarkDoNotContact
      );
      internalTrace = true;
    }
    return this.http
      .put<void>(
        `${this.tenantConfig.api.endpoint}/admin/customer/${customerId}/donotcontact`,
        undefined,
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminMarkDoNotContact, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminMarkDoNotContact);
        })
      );
  }

  unmarkDoNotContact(
    customerId: string,
    trace?: HTTPTraceHeader
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminUnmarkDoNotContact);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminUnmarkDoNotContact
      );
      internalTrace = true;
    }
    return this.http
      .delete<void>(
        `${this.tenantConfig.api.endpoint}/admin/customer/${customerId}/donotcontact`,
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminUnmarkDoNotContact, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminUnmarkDoNotContact);
        })
      );
  }

  bulkAddTags(
    tags: string[],
    customers: string[],
    trace?: HTTPTraceHeader | undefined
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminBulkAddTags);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminBulkAddTags
      );
      internalTrace = true;
    }
    return this.http
      .post<void>(
        `${this.tenantConfig.api.endpoint}/admin/customer/tag`,
        { tags, customers },
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminBulkAddTags, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminBulkAddTags);
        })
      );
  }

  bulkRemoveTags(
    tags: string[],
    customers: string[],
    trace?: HTTPTraceHeader | undefined
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminBulkRemoveTags);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminBulkRemoveTags
      );
      internalTrace = true;
    }
    return this.http
      .delete<void>(`${this.tenantConfig.api.endpoint}/admin/customer/tag`, {
        body: { tags, customers },
        headers: this.processHeaders(trace),
      })
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminBulkRemoveTags, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminBulkRemoveTags);
        })
      );
  }

  bulkAddToLists(
    lists: string[],
    customers: string[],
    trace?: HTTPTraceHeader | undefined
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminBulkAddLists);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminBulkAddLists
      );
      internalTrace = true;
    }
    return this.http
      .post<void>(
        `${this.tenantConfig.api.endpoint}/admin/customer/list`,
        { lists, customers },
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminBulkAddLists, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminBulkAddLists);
        })
      );
  }

  bulkRemoveFromLists(
    lists: string[],
    customers: string[],
    trace?: HTTPTraceHeader | undefined
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminBulkRemoveLists);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminBulkRemoveLists
      );
      internalTrace = true;
    }
    return this.http
      .delete<void>(`${this.tenantConfig.api.endpoint}/admin/customer/list`, {
        body: { lists, customers },
        headers: this.processHeaders(trace),
      })
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminBulkRemoveLists, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminBulkRemoveLists);
        })
      );
  }

  addTag(
    tag: string,
    customerId: string,
    trace?: HTTPTraceHeader
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminAddTag);
      trace = this.traceService.getHTTPHeaderPropagators(SpanTypes.adminAddTag);
      internalTrace = true;
    }
    return this.http
      .put<void>(
        `${this.tenantConfig.api.endpoint}/admin/customer/${customerId}/tag/${tag}`,
        { tag },
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminAddTag, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace && this.traceService.endSpan(SpanTypes.adminAddTag);
        })
      );
  }

  removeTag(
    tag: string,
    customerId: string,
    trace?: HTTPTraceHeader
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminRemoveTag);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminRemoveTag
      );
      internalTrace = true;
    }
    return this.http
      .delete<void>(
        `${this.tenantConfig.api.endpoint}/admin/customer/${customerId}/tag/${tag}`,
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminRemoveTag, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace && this.traceService.endSpan(SpanTypes.adminRemoveTag);
        })
      );
  }

  addToList(
    list: string,
    customerId: string,
    trace?: HTTPTraceHeader
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminAddList);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminAddList
      );
      internalTrace = true;
    }
    return this.http
      .put<void>(
        `${this.tenantConfig.api.endpoint}/admin/customer/${customerId}/list/${list}`,
        { list },
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminAddList, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace && this.traceService.endSpan(SpanTypes.adminAddList);
        })
      );
  }

  removeFromList(
    list: string,
    customerId: string,
    trace?: HTTPTraceHeader
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminRemoveList);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminRemoveList
      );
      internalTrace = true;
    }
    return this.http
      .delete<void>(
        `${this.tenantConfig.api.endpoint}/admin/customer/${customerId}/list/${list}`,
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminRemoveList, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace && this.traceService.endSpan(SpanTypes.adminRemoveList);
        })
      );
  }
}
