import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  AppConfig,
  APP_CONFIG,
  ANALYTICS_IS_SUPPORTED,
} from '../models/config';
import {
  Analytics,
  logEvent,
  setUserId as setUserIdFireAnalytics,
  setUserProperties,
} from '@angular/fire/analytics';
import { isPlatformServer } from '@angular/common';
import {
  newTracker,
  trackPageView,
  setUserId,
  TrackerConfiguration,
  addGlobalContexts,
  ConditionalContextProvider,
  ContextPrimitive,
  CommonEventProperties,
  PageViewEvent,
  trackSelfDescribingEvent,
  SelfDescribingEvent,
  SelfDescribingJson,
  BrowserPlugin,
} from '@snowplow/browser-tracker';
import {
  Cart,
  CheckoutStep,
  CommonEcommerceEventProperties,
  ListClickEvent,
  ListViewEvent,
  Product,
  SnowplowEcommercePlugin,
  SPTransaction,
  trackAddToCart,
  trackCheckoutStep,
  trackProductListClick,
  trackProductListView,
  trackProductView,
  trackRemoveFromCart,
  trackTransaction,
  trackTransactionError,
  TransactionError,
} from '@snowplow/browser-plugin-snowplow-ecommerce';
import {
  ErrorEventProperties,
  ErrorTrackingPlugin,
  trackError,
} from '@snowplow/browser-plugin-error-tracking';
import { GlobalFacade } from '../+state/global.facade';
import { Subject, take, takeUntil } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { AutoDestroy } from '@sidkik/shared';
import {
  CartItemProperties,
  OrderRequestProperties,
  PurchasableProperties,
} from '@sidkik/db';

export enum ScreenType {
  Page = 'page',
  Post = 'post',
  Thread = 'thread',
  Shop = 'shop',
  Course = 'course',
  Challenge = 'challenge',
  Space = 'space',
  Product = 'product',
  Module = 'module',
  Section = 'section',
  Help = 'help',
}

export interface ScreenViewMetadata {
  rootType?: string;
  rootId?: string;
  subType?: string;
  subId?: string;
  tertiaryType?: string;
  tertiaryId?: string;
}

export enum Category {
  Shop = 'shop',
  Course = 'course',
  Challenge = 'challenge',
  User = 'user',
}

export enum ShopAction {
  ViewItem = 'view_item',
  AddToCart = 'add_to_cart',
  RemoveFromCart = 'remove_from_cart',
  ViewCart = 'view_cart',
  BeginCheckout = 'begin_checkout',
  AddPaymentInfo = 'add_payment_info',
  Purchase = 'purchase',
}

export enum CourseAction {
  StartedCourse = 'started_course',
  CompletedCourse = 'completed_course',
  StartedSection = 'started_section',
  CompletedSection = 'completed_section',
  UndoCompletedSection = 'undo_completed_section',
}

export enum ChallengeAction {
  StartedChallenge = 'started_challenge',
  CompletedChallenge = 'completed_challenge',
  AddedChallengeActivity = 'added_challenge_activity',
  EditedChallengeActivity = 'edited_challenge_activity',
  RemovedChallengeActivity = 'removed_challenge_activity',
}

export enum CommunityAction {
  ViewedThread = 'viewed_thread',
  ViewedSpace = 'viewed_space',
  ViewedThreadList = 'viewed_thread_list',
  CommentedOnThread = 'commented_on_thread',
  ClappedThread = 'clapped_thread',
}

export enum UserAction {
  Login = 'login',
}

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  private readonly ANONYMOUS_TOKEN_KEY = 'anonymous_tracking_id';
  private readonly TOKEN_EXPIRY_DAYS = 7;

  @AutoDestroy()
  private onDestroy$ = new Subject<void>();

  customerId: string | undefined;
  jwt: string | undefined;
  customerIdSet = false;
  namespace = 'sidkik';
  schema = 'iglu:com.snowplowanalytics.snowplow/contexts/jsonschema/1-0-0';
  lastPath: string | undefined;
  retryCount = 0;

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    @Inject('APP_VERSION') private appVersion: string,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(ANALYTICS_IS_SUPPORTED)
    private isFirebaseAnalyticsSupported: boolean,
    private readonly globalFacade: GlobalFacade,
    private analytics: Analytics
  ) {
    this.globalFacade.jwt$.pipe(takeUntil(this.onDestroy$)).subscribe((jwt) => {
      this.jwt = jwt as string;
    });
    logger.debug(
      'global:events.service:constructor',
      'isOkToTrack',
      this.okToTrack(),
      'isFirebaseAnalyticsSupported',
      this.isFirebaseAnalyticsSupported,
      'platformId',
      this.platformId
    );
    if (this.okToTrack()) {
      const spConfig: TrackerConfiguration = {
        contexts: {
          session: true,
          webPage: true,
          browser: true,
        },
        postPath: '/collector/se',
        // Custom function to add headers to each request
        credentials: 'omit',
        customFetch: async (request: Request, options?: RequestInit) => {
          logger.trace(
            'global:events.service:customFetch',
            'custom fetch',
            request
          );

          if (options) {
            options.headers = {
              ...options.headers,
              ...(await this.getSecurityHeaders()),
            };
          } else {
            options = {
              headers: await this.getSecurityHeaders(),
            };
          }

          return fetch(request, options);
        },
        plugins: [
          SnowplowEcommercePlugin(),
          ErrorTrackingPlugin() as BrowserPlugin,
        ],
      };
      this.namespace = `sidkik-${this.appConfig?.telemetry?.trace?.serviceName}`;
      const endpoint = `${this.appConfig.api.endpoint}`;
      newTracker(this.namespace, endpoint, spConfig);
      logger.debug(
        'global:events.service:constructor',
        'newTracker',
        this.namespace,
        endpoint,
        spConfig
      );
      this.setupGlobalContexts();
    }
  }

  private async getOrCreateAnonymousToken(): Promise<string> {
    logger.debug(
      'global:events.service:getOrCreateAnonymousToken',
      'getting or creating anonymous token'
    );
    const token = localStorage.getItem(this.ANONYMOUS_TOKEN_KEY);
    let tokenData;

    try {
      tokenData = token ? JSON.parse(token) : null;
    } catch {
      tokenData = null;
    }

    // Check if token exists and is still valid
    if (!tokenData || this.isTokenExpired(tokenData.createdAt)) {
      // Generate a new anonymous token with rate limiting info
      tokenData = {
        token: uuidv4(),
        createdAt: Date.now(),
        eventCount: 0,
      };
      localStorage.setItem(this.ANONYMOUS_TOKEN_KEY, JSON.stringify(tokenData));
    }

    return tokenData.token;
  }

  private isTokenExpired(createdAt: number): boolean {
    const expiryTime = createdAt + this.TOKEN_EXPIRY_DAYS * 24 * 60 * 60 * 1000;
    return Date.now() > expiryTime;
  }

  private generateContextSchema(context: string): string {
    // after /contexts/ insert the context
    return this.schema.replace('/contexts/', `/contexts/${context}/`);
  }

  private async getSecurityHeaders(): Promise<Record<string, string>> {
    logger.trace(
      'global:events.service:getSecurityHeaders',
      'getting security headers'
    );
    logger.trace('global:events.service:getSecurityHeaders', 'jwt', this.jwt);
    // If user is authenticated, use their JWT
    if (this.jwt) {
      return {
        Authorization: `Bearer ${this.jwt}`,
        'X-Client-ID': this.getOrCreateClientId(),
      };
    }

    // For anonymous users, use a temporary tracking token
    return {
      'X-Anonymous-Token': await this.getOrCreateAnonymousToken(),
      'X-Client-ID': this.getOrCreateClientId(),
      'X-Tenant-ID': this.appConfig.firebase.tenantId || '',
    };
  }

  private getOrCreateClientId(): string {
    let clientId = localStorage.getItem('client_id');
    if (!clientId) {
      clientId = uuidv4();
      localStorage.setItem('client_id', clientId);
    }
    return clientId;
  }

  private setupGlobalContexts(): void {
    addGlobalContexts(
      {
        tenancy: {
          schema: this.generateContextSchema('tenancy'),
          data: {
            tenantId: this.appConfig.firebase.tenantId,
          },
        },
        appDetails: {
          schema: this.generateContextSchema('app_details'),
          data: {
            projectId: this.appConfig.firebase.projectId,
            appVersion: this.appVersion,
          },
        },
      } as Record<string, ConditionalContextProvider | ContextPrimitive>,
      [this.namespace]
    );
  }

  private setUserContexts(): void {
    if (!this.customerId) return;
    setUserId(this.customerId, [this.namespace]);
    addGlobalContexts(
      {
        user: {
          schema: this.generateContextSchema('user'),
          data: {
            customerId: this.customerId,
          },
        },
      },
      [this.namespace]
    );
    this.customerIdSet = true;
  }

  private okToTrack(): boolean {
    return !isPlatformServer(this.platformId);
  }

  private extractUtmParameters(): Record<string, string> | null {
    if (isPlatformServer(this.platformId)) return null;

    const urlParams = new URLSearchParams(window.location.search);
    const utmParams = {
      source: urlParams.get('utm_source'),
      medium: urlParams.get('utm_medium'),
      campaign: urlParams.get('utm_campaign'),
      term: urlParams.get('utm_term'),
      content: urlParams.get('utm_content'),
    };

    // Only return if at least one UTM parameter is present
    const validParams = Object.entries(utmParams)
      .filter(([_, value]) => value !== null)
      .reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value as string,
        }),
        {} as Record<string, string>
      );

    return Object.keys(validParams).length > 0 ? validParams : null;
  }

  /**
   * Parse an error stack trace into an array of structured location information
   */
  private parseErrorStack(stack: string): Array<{
    filename?: string;
    lineno?: number;
    colno?: number;
    functionName?: string;
  }> {
    try {
      const result: Array<{
        filename?: string;
        lineno?: number;
        colno?: number;
        functionName?: string;
      }> = [];

      // Split the stack trace into lines
      const stackLines = stack.split('\n');

      // Skip the first line (error message) and look at each stack frame
      for (let i = 1; i < stackLines.length; i++) {
        const line = stackLines[i].trim();

        // Skip frames with no information
        if (!line || !line.startsWith('at ')) {
          continue;
        }

        // Skip frames like "at Generator.next (<anonymous>)" which don't have file info
        if (line.includes('<anonymous>') && !line.includes('.js:')) {
          // Extract function name if present
          const functionMatch = line.match(/at\s+([^\s]+)\s+\(<anonymous>\)/);
          if (functionMatch && functionMatch[1]) {
            result.push({
              functionName: functionMatch[1],
            });
          }
          continue;
        }

        // Handle URLs with multiple colons (like https://)
        // For lines like: at https://local.sidkik.app:4201/main.js:67:13
        // or: at AppComponent.ngOnInit (https://local.sidkik.app:4201/main.js:68:7)

        // Extract function name and URL part
        let functionName: string | undefined = undefined;
        let urlPart: string;

        if (line.includes('(') && line.includes(')')) {
          // Format: at Something (URL:line:column)
          const funcMatch = line.match(/at\s+([^(]+)\s+\(/);
          if (funcMatch && funcMatch[1]) {
            functionName = funcMatch[1].trim();
          }

          const match = line.match(/\(([^)]+)\)/);
          if (match && match[1]) {
            urlPart = match[1];
          } else {
            continue; // Can't parse this line, try the next one
          }
        } else {
          // Format: at URL:line:column
          urlPart = line.substring(3); // Remove the "at " prefix
        }

        // Now extract the line:column information from the end of urlPart
        // This handles URLs like https://domain:port/path.js:line:column

        // Find the last colon before a number
        const lastColonIndex = urlPart.lastIndexOf(':');
        if (lastColonIndex === -1) {
          // No line/column info, just add the function info if we have it
          if (functionName) {
            result.push({ functionName });
          }
          continue;
        }

        const colno = parseInt(urlPart.substring(lastColonIndex + 1), 10);
        if (isNaN(colno)) {
          // No valid column number, just add the function info if we have it
          if (functionName) {
            result.push({ functionName });
          }
          continue;
        }

        // Find the second-to-last colon
        const secondLastColonIndex = urlPart.lastIndexOf(
          ':',
          lastColonIndex - 1
        );
        if (secondLastColonIndex === -1) {
          // No line info, just add function and column if we have them
          result.push({
            functionName,
            colno,
          });
          continue;
        }

        const lineno = parseInt(
          urlPart.substring(secondLastColonIndex + 1, lastColonIndex),
          10
        );
        if (isNaN(lineno)) {
          // No valid line number, add what we have
          result.push({
            functionName,
            colno,
          });
          continue;
        }

        // The filename is everything before the second-to-last colon
        const filename = urlPart.substring(0, secondLastColonIndex);

        result.push({
          filename,
          lineno,
          colno,
          functionName,
        });
      }

      return result;
    } catch (parseError) {
      // If parsing fails, return an empty array
      logger.warn('Failed to parse error stack:', parseError);
      return [];
    }
  }

  /**
   * Attempts to resolve source map information asynchronously
   */
  private async resolveSourceMap(jsLocation: {
    filename: string;
    lineno: number;
    colno: number;
  }): Promise<{ filename?: string; lineno?: number; colno?: number } | null> {
    try {
      // Import the source-map-js package (browser compatible)
      const { SourceMapConsumer } = await import('source-map-js');

      // In a production environment, you would need to:
      // 1. Fetch the source map file from your server or CDN
      // 2. Parse it using the SourceMapConsumer

      // This is a simplified example assuming source maps are accessible
      const sourceMapUrl = `${jsLocation.filename}.map`;

      // Fetch the source map content (you may need to adjust this based on your setup)
      const response = await fetch(sourceMapUrl);
      if (!response.ok) {
        throw new Error(`Failed to fetch source map: ${response.statusText}`);
      }

      const rawSourceMap = await response.json();

      // Initialize the source map consumer - source-map-js has a synchronous API
      // unlike source-map 0.7+ which requires initialization with WASM
      const consumer = new SourceMapConsumer(rawSourceMap);

      // Find the original position in the TypeScript source
      const originalPosition = consumer.originalPositionFor({
        line: jsLocation.lineno,
        column: jsLocation.colno,
      });

      if (originalPosition.source) {
        return {
          filename: originalPosition.source,
          lineno: originalPosition.line || jsLocation.lineno,
          colno: originalPosition.column || jsLocation.colno,
        };
      }

      return jsLocation;
    } catch (error) {
      logger.warn('Error resolving source map:', error);
      return jsLocation;
    }
  }

  /**
   * Attempts to resolve source map information asynchronously for all frames in a stack trace
   */
  private async resolveSourceMapForStack(
    frames: Array<{
      filename?: string;
      lineno?: number;
      colno?: number;
      functionName?: string;
    }>
  ): Promise<
    Array<{
      filename?: string;
      lineno?: number;
      colno?: number;
      functionName?: string;
    }>
  > {
    const result: Array<{
      filename?: string;
      lineno?: number;
      colno?: number;
      functionName?: string;
    }> = [];

    // Process each frame that has filename information
    for (const frame of frames) {
      if (
        frame.filename &&
        frame.filename.endsWith('.js') &&
        frame.lineno &&
        frame.colno
      ) {
        try {
          // Try to resolve this frame with source maps
          const mapped = await this.resolveSourceMap({
            filename: frame.filename,
            lineno: frame.lineno,
            colno: frame.colno,
          });

          // Add the resolved frame with the function name preserved
          result.push({
            filename: mapped?.filename,
            lineno: mapped?.lineno,
            colno: mapped?.colno,
            functionName: frame.functionName,
          });
        } catch (error) {
          // If mapping fails, add the original frame
          result.push(frame);
        }
      } else {
        // No filename or not a JS file, add as is
        result.push(frame);
      }
    }

    return result;
  }

  /**
   * Update the trackError method to work with the array of stack frames
   */
  public trackError(error: Error): void {
    // always track errors
    const event: ErrorEventProperties & CommonEventProperties = {
      message: error.message,
      error: error,
    };

    // If no stack trace, just track the error immediately
    if (!error.stack) {
      trackError(event, [this.namespace]);
      return;
    }

    // Parse stack trace to get file, line, column information for all frames
    const stackFrames = this.parseErrorStack(error.stack);

    // If no valid stack frames, just track the error immediately
    if (stackFrames.length === 0) {
      trackError(event, [this.namespace]);
      return;
    }

    // Use the first frame with location info for tracking
    const firstLocationFrame = stackFrames.find(
      (frame) => frame.filename && frame.lineno && frame.colno
    );

    if (firstLocationFrame) {
      event.filename = firstLocationFrame.filename;
      event.lineno = firstLocationFrame.lineno;
      event.colno = firstLocationFrame.colno;
    }

    // Try to apply source maps asynchronously for all frames
    // We'll wait for this to complete before tracking
    this.resolveSourceMapForStack(stackFrames)
      .then((mappedFrames) => {
        // Source map resolution succeeded

        // Update the event with the source-mapped information
        const firstMappedFrame = mappedFrames.find(
          (frame) => frame.filename && frame.lineno && frame.colno
        );

        if (firstMappedFrame) {
          event.filename = firstMappedFrame.filename;
          event.lineno = firstMappedFrame.lineno;
          event.colno = firstMappedFrame.colno;
        }

        // Create a formatted source-mapped stack trace
        const formattedStack = mappedFrames
          .map((frame) => {
            if (frame.functionName) {
              return `    at ${frame.functionName} (${
                frame.filename || 'unknown'
              }:${frame.lineno || '?'}:${frame.colno || '?'})`;
            } else {
              return `    at ${frame.filename || 'unknown'}:${
                frame.lineno || '?'
              }:${frame.colno || '?'}`;
            }
          })
          .join('\n');

        // Preserve the original error message
        const errorMessage =
          error.stack?.split('\n')[0] || `Error: ${error.message}`;
        const sourceMapStack = `${errorMessage}\n${formattedStack}`;
        error.stack = sourceMapStack;

        // Finally track the error with source-mapped information
        trackError(event, [this.namespace]);
      })
      .catch((err) => {
        // Source map resolution failed, but we still want to track the error
        logger.warn('Failed to resolve source maps for stack:', err);
        trackError(event, [this.namespace]);
      });
  }

  public trackScreenView(
    screen: string,
    path: string,
    metadata?: ScreenViewMetadata
  ): void {
    logger.debug(
      'global:events.service:trackScreenView',
      'tracking screen view',
      screen,
      path,
      'okToTrack',
      this.okToTrack()
    );
    if (!this.okToTrack()) return;
    if (path !== this.lastPath) {
      const pve = {
        title: screen,
        timestamp: Date.now(),
      } as PageViewEvent & CommonEventProperties;

      const contexts = [];

      if (metadata) {
        contexts.push({
          schema: this.generateContextSchema('screen'),
          data: metadata,
        });
      }

      const utmParams = this.extractUtmParameters();
      if (utmParams) {
        contexts.push({
          schema:
            'iglu:com.snowplowanalytics.snowplow/campaign_attribution/jsonschema/1-0-1',
          data: utmParams,
        });
      }

      if (contexts.length > 0) {
        pve.context = contexts as SelfDescribingJson<Record<string, unknown>>[];
      }

      trackPageView(pve, [this.namespace]);
      this.lastPath = path;
    }
    if (this.isFirebaseAnalyticsSupported) {
      logEvent(this.analytics, 'screen_view' as any, {
        app_version: this.appVersion,
        screen_name: screen,
        firebase_screen: screen,
        page_path: path,
      });
    }
  }

  public viewProductList(products: PurchasableProperties[]): void {
    if (!this.okToTrack()) return;
    trackProductListView(
      {
        name: 'product list',
        products: products.map((p) => ({
          id: p.data.product.id,
          price: p.data.sku[0].data.price,
          name: p.data.product.data.name,
          currency: 'USD',
          category: p.data.product.data.subtype,
        })),
      } as ListViewEvent & CommonEcommerceEventProperties,
      [this.namespace]
    );
  }

  public clickProductListItem(item: PurchasableProperties): void {
    if (!this.okToTrack()) return;
    trackProductListClick(
      {
        product: {
          id: item.data.product.id,
          price: item.data.sku[0].data.price,
          name: item.data.product.data.name,
          currency: 'USD',
          category: item.data.product.data.subtype,
        },
        name: 'product list',
      } as ListClickEvent & CommonEcommerceEventProperties,
      [this.namespace]
    );
  }

  public viewItem(item: PurchasableProperties): void {
    if (!this.okToTrack()) return;
    if (!!item && !!item.data && !!item.data.sku && !!item.data.sku[0]) {
      trackProductView(
        {
          id: item.data.product.id,
          price: item.data.sku[0].data.price,
          name: item.data.product.data.name,
          category: item.data.product.data.subtype,
          currency: 'USD',
        } as Product & CommonEcommerceEventProperties,
        [this.namespace]
      );
      if (this.isFirebaseAnalyticsSupported) {
        logEvent(this.analytics, 'view_item' as any, {
          app_version: this.appVersion,
          currency: 'USD',
          value: this.convertToUSD(item.data.sku[0].data.price),
          items: [
            {
              item_id: item.data.product.id,
              item_name: item.data.product.data.name,
              price: this.convertToUSD(item.data.sku[0].data.price),
            },
          ],
        });
      }
    }
  }

  // need to add the cart to the event with the cart total
  public addToCart(
    item: CartItemProperties,
    allItems: CartItemProperties[]
  ): void {
    if (!this.okToTrack()) return;
    if (!!item && !!item.data && !!item.data.sku) {
      let total = 0;
      if (allItems && allItems.length > 0) {
        total = allItems.reduce((acc, cur) => acc + cur.data.sku.data.price, 0);
      }
      trackAddToCart(
        {
          products: [
            {
              id: item.data.product.id,
              price: item.data.sku.data.price,
              name: item.data.product.data.name,
              category: item.data.product.data.subtype,
              currency: 'USD',
            },
          ],
          total_value: total,
          currency: 'USD',
        } as Cart & CommonEcommerceEventProperties,
        [this.namespace]
      );
      if (this.isFirebaseAnalyticsSupported) {
        logEvent(this.analytics, 'add_to_cart' as any, {
          app_version: this.appVersion,
          currency: 'USD',
          value: this.convertToUSD(item.data.sku.data.price),
          items: [
            {
              item_id: item.data.product.id,
              item_name: item.data.product.data.name,
              price: this.convertToUSD(item.data.sku.data.price),
            },
          ],
        });
      }
    }
  }

  // need to add the cart to the event with the cart total
  public removeFromCart(
    item: CartItemProperties,
    allItems: CartItemProperties[]
  ): void {
    if (!this.okToTrack()) return;
    if (!!item && !!item.data && !!item.data.sku) {
      let total = 0;
      if (allItems && allItems.length > 0) {
        total = allItems.reduce((acc, cur) => acc + cur.data.sku.data.price, 0);
      }
      trackRemoveFromCart(
        {
          products: [
            {
              id: item.data.product.id,
              name: item.data.product.data.name,
              category: item.data.product.data.subtype,
              price: item.data.sku.data.price,
              currency: 'USD',
            },
          ],
          total_value: total,
          currency: 'USD',
        },
        [this.namespace]
      );
      if (this.isFirebaseAnalyticsSupported) {
        logEvent(this.analytics, 'remove_from_cart' as any, {
          app_version: this.appVersion,
          currency: 'USD',
          value: this.convertToUSD(item.data.sku.data.price),
          items: [
            {
              item_id: item.data.product.id,
              item_name: item.data.product.data.name,
              price: this.convertToUSD(item.data.sku.data.price),
            },
          ],
        });
      }
    }
  }

  public viewCart(items: any[]): void {
    if (!this.okToTrack()) return;
    // trackSelfDescribingEvent(
    //   {
    //     event: {
    //       schema: this.schema,
    //       data: {
    //         value: items.reduce((acc, cur) => acc + cur.data.sku.data.price, 0),
    //         items: items.map((item) => ({
    //           itemId: item.data.product.id,
    //           itemName: item.data.product.data.name,
    //           price: item.data.sku.data.price,
    //         })),
    //         category: Category.Shop,
    //         action: ShopAction.ViewCart,
    //         timestamp: Date.now(),
    //       },
    //     },
    //   } as SelfDescribingEvent<Record<string, unknown>> &
    //     CommonEventProperties<Record<string, unknown>>,
    //   [this.namespace]
    // );
    if (this.isFirebaseAnalyticsSupported) {
      logEvent(this.analytics, 'view_cart' as any, {
        app_version: this.appVersion,
        currency: 'USD',
        value: items.reduce((acc, cur) => acc + cur.data.sku.data.price, 0),
        items: items.map((item) => ({
          item_id: item.data.product.id,
          item_name: item.data.product.data.name,
          price: item.data.sku.data.price,
        })),
      });
    }
  }

  public beginCheckout(items: any[]): void {
    if (!this.okToTrack()) return;
    trackCheckoutStep(
      {
        step: 1, // this is the landing on the checkout page (after clicking checkout)
      } as CheckoutStep & CommonEcommerceEventProperties,
      [this.namespace]
    );
    if (this.isFirebaseAnalyticsSupported) {
      logEvent(this.analytics, 'begin_checkout' as any, {
        app_version: this.appVersion,
        currency: 'USD',
        value: items.reduce((acc, cur) => acc + cur.data.sku.data.price, 0),
        items: items.map((item) => ({
          item_id: item.data.product.id,
          item_name: item.data.product.data.name,
          price: item.data.sku.data.price,
        })),
      });
    }
  }

  public applyCoupon(promoCode: string): void {
    if (!this.okToTrack()) return;
    trackCheckoutStep(
      {
        step: 2, // this is the application of the promo code (optional)
        coupon_code: promoCode,
      } as CheckoutStep & CommonEcommerceEventProperties,
      [this.namespace]
    );
  }

  public completePaymentInfo(paymentType: string): void {
    if (!this.okToTrack()) return;
    trackCheckoutStep(
      {
        step: 3, // this is the completion of the payment info
        payment_type: paymentType,
      } as CheckoutStep & CommonEcommerceEventProperties,
      [this.namespace]
    );
  }

  public addPaymentInfo(items: any[], total: number): void {
    if (!this.okToTrack()) return;
    trackSelfDescribingEvent(
      {
        event: {
          schema: this.schema,
          data: {
            value: total,
            items: items.map((item) => ({
              itemId: item.data.product.id,
              itemName: item.data.product.data.name,
              price: item.data.sku.data.price,
            })),
            category: Category.Shop,
            action: ShopAction.AddPaymentInfo,
            timestamp: Date.now(),
          },
        },
      } as SelfDescribingEvent<Record<string, unknown>> &
        CommonEventProperties<Record<string, unknown>>,
      [this.namespace]
    );
    if (this.isFirebaseAnalyticsSupported) {
      logEvent(this.analytics, 'add_payment_info' as any, {
        app_version: this.appVersion,
        currency: 'USD',
        value: total,
        items: items.map((item) => ({
          item_id: item.data.product.id,
          item_name: item.data.product.data.name,
          price: item.data.sku.data.price,
        })),
      });
    }
  }

  public orderComplete(order: OrderRequestProperties, total: number): void {
    if (!this.okToTrack()) return;
    const discountCode = order.data.promotionCode?.data?.code;
    const paymentMethod =
      total > 0 ? order.data.paymentMethod?.data.brand ?? '' : 'free';
    const discountAmount = order.data.cart.data.items.reduce(
      (acc, cur) => acc + (cur.data.discountAmount ?? 0),
      0
    );

    const products: Product[] = order.data.cart.data.items.map((item) => ({
      id: item.data.product.id,
      price: item.data.sku.data.price,
      name: item.data.product.data.name,
      category: item.data.product.data.subtype,
      currency: 'USD',
    }));

    const transaction: SPTransaction & CommonEcommerceEventProperties = {
      transaction_id: order.id,
      revenue: total,
      currency: 'USD',
      payment_method: paymentMethod,
      products,
    };

    if (discountCode) {
      transaction.discount_code = discountCode;
    }

    if (discountAmount > 0) {
      transaction.discount_amount = discountAmount;
    }

    trackTransaction(transaction, [this.namespace]);
  }

  public orderIssue(order: OrderRequestProperties, total: number): void {
    if (!this.okToTrack()) return;
    const paymentMethod =
      total > 0 ? order.data.paymentMethod?.data.brand ?? '' : 'free';
    trackTransactionError(
      {
        error_description: order.integrations.state.message ?? 'unknown',
        error_type: 'hard',
        transaction: {
          transaction_id: order.id,
          revenue: total,
          currency: 'USD',
          payment_method: paymentMethod,
        },
      } as TransactionError & CommonEcommerceEventProperties,
      [this.namespace]
    );
  }

  public purchase(
    items: any[],
    total: number,
    coupon?: string | undefined
  ): void {
    if (!this.okToTrack()) return;
    trackSelfDescribingEvent(
      {
        event: {
          schema: this.schema,
          data: {
            value: total,
            items: items.map((item) => ({
              itemId: item.data.product.id,
              itemName: item.data.product.data.name,
              price: item.data.sku.data.price,
            })),
            category: Category.Shop,
            action: ShopAction.Purchase,
            timestamp: Date.now(),
          },
        },
      } as SelfDescribingEvent<Record<string, unknown>> &
        CommonEventProperties<Record<string, unknown>>,
      [this.namespace]
    );
    if (this.isFirebaseAnalyticsSupported) {
      logEvent(this.analytics, 'purchase' as any, {
        app_version: this.appVersion,
        coupon,
        currency: 'USD',
        value: this.convertToUSD(total),
        items: items.map((item) => ({
          item_id: item.data.product.id,
          item_name: item.data.product.data.name,
          price: this.convertToUSD(item.data.sku.data.price),
        })),
      });
    }
  }

  public setUser(uid: string, email?: string): void {
    this.customerId = uid;
    this.setUserContexts();
    if (!this.okToTrack()) return;
    if (this.isFirebaseAnalyticsSupported) {
      setUserIdFireAnalytics(this.analytics, uid);
      setUserProperties(this.analytics, {
        app_version: this.appVersion,
        // sid_email: email,
      });
    }
  }

  public trackLogin(): void {
    if (!this.okToTrack()) return;
    // need to check to make sure the user has a customer id associated with them
    // if not, need to retry the login event once per minute for 5 minutes
    // if the customer id is not found after 5 minutes, then we can stop retrying

    logger.trace('global:events.service', 'trackLogin', 'tracking login');
    if (!this.customerId) {
      logger.warn(
        'global:events.service',
        'trackLogin',
        'no customer id found, retrying'
      );
      this.retryLogin();
    }

    // check if the customer was just created
    // should have me based on the customer id being set in the service
    this.globalFacade.me$.pipe(take(1)).subscribe((me) => {
      const delay = 15000;
      if (!me) {
        logger.warn(
          'global:events.service',
          'trackLogin',
          'no me found, retrying in 5'
        );
        setTimeout(() => {
          this.trackLogin();
        }, delay);
        return;
      }
      // if the customer was just created, wait before firing the login event
      const now = new Date().getTime();
      const created = me?.meta?.created ?? 0;
      const diff = now - created;
      logger.trace('global:events.service', 'trackLogin', 'customer created', {
        created,
        now,
        diff,
      });
      // check if less than 5 minutes
      if (diff < delay) {
        setTimeout(() => {
          this.trackLogin();
        }, delay);
        return;
      }

      logger.trace('global:events.service', 'trackLogin', 'tracking login', {
        customerId: this.customerId,
      });

      trackSelfDescribingEvent(
        {
          event: {
            schema: this.schema,
            data: {
              category: Category.User,
              action: UserAction.Login,
              timestamp: Date.now(),
            },
          },
        } as SelfDescribingEvent<Record<string, unknown>> &
          CommonEventProperties<Record<string, unknown>>,
        [this.namespace]
      );
      if (this.isFirebaseAnalyticsSupported) {
        logEvent(this.analytics, 'login' as any, {
          app_version: this.appVersion,
        });
      }
    });
  }

  private retryLogin(): void {
    setTimeout(() => {
      this.retryCount++;
      if (this.retryCount > 5) {
        logger.warn(
          'global:events.service',
          'retryLogin',
          'max retries reached'
        );
        return;
      }
      logger.warn(
        'global:events.service',
        'retryLogin',
        'retrying login',
        `retry ${this.retryCount}`
      );
      this.trackLogin();
    }, 60000);
  }

  public trackCourseStart(courseId: string): void {
    if (!this.okToTrack()) return;
    trackSelfDescribingEvent(
      {
        event: {
          schema: this.schema,
          data: {
            category: Category.Course,
            action: CourseAction.StartedCourse,
            timestamp: Date.now(),
            courseId,
          },
        },
      } as SelfDescribingEvent<Record<string, unknown>> &
        CommonEventProperties<Record<string, unknown>>,
      [this.namespace]
    );
  }

  public trackCourseCompletion(courseId: string): void {
    if (!this.okToTrack()) return;
    trackSelfDescribingEvent(
      {
        event: {
          schema: this.schema,
          data: {
            category: Category.Course,
            action: CourseAction.CompletedCourse,
            timestamp: Date.now(),
            courseId,
          },
        },
      } as SelfDescribingEvent<Record<string, unknown>> &
        CommonEventProperties<Record<string, unknown>>,
      [this.namespace]
    );
  }

  public trackCourseSectionStart(
    courseId: string,
    sectionId: string,
    percentageStarted: number
  ): void {
    if (!this.okToTrack()) return;
    trackSelfDescribingEvent(
      {
        event: {
          schema: this.schema,
          data: {
            category: Category.Course,
            action: CourseAction.StartedSection,
            timestamp: Date.now(),
            courseId,
            sectionId,
            percentageStarted,
          },
        },
      } as SelfDescribingEvent<Record<string, unknown>> &
        CommonEventProperties<Record<string, unknown>>,
      [this.namespace]
    );
  }

  public trackCourseSectionCompletion(
    courseId: string,
    sectionId: string,
    percentageCompleted: number
  ): void {
    if (!this.okToTrack()) return;
    trackSelfDescribingEvent(
      {
        event: {
          schema: this.schema,
          data: {
            category: Category.Course,
            action: CourseAction.CompletedSection,
            timestamp: Date.now(),
            courseId,
            sectionId,
            percentageCompleted: Math.floor(percentageCompleted),
          },
        },
      } as SelfDescribingEvent<Record<string, unknown>> &
        CommonEventProperties<Record<string, unknown>>,
      [this.namespace]
    );
  }

  public trackCourseSectionCompletionUndo(
    courseId: string,
    sectionId: string,
    percentageCompleted: number
  ): void {
    if (!this.okToTrack()) return;
    trackSelfDescribingEvent(
      {
        event: {
          schema: this.schema,
          data: {
            category: Category.Course,
            action: CourseAction.UndoCompletedSection,
            timestamp: Date.now(),
            courseId,
            sectionId,
            percentageCompleted: Math.floor(percentageCompleted),
          },
        },
      } as SelfDescribingEvent<Record<string, unknown>> &
        CommonEventProperties<Record<string, unknown>>,
      [this.namespace]
    );
  }

  public trackChallengeStart(
    challengeId: string,
    challengeProgressId: string,
    numberOfActivities: number
  ): void {
    if (!this.okToTrack()) return;
    trackSelfDescribingEvent(
      {
        event: {
          schema: this.schema,
          data: {
            category: Category.Challenge,
            action: ChallengeAction.StartedChallenge,
            timestamp: Date.now(),
            challengeId,
            numberOfActivities,
            challengeProgressId,
          },
        },
      } as SelfDescribingEvent<Record<string, unknown>> &
        CommonEventProperties<Record<string, unknown>>,
      [this.namespace]
    );
  }

  public trackAddChallengeActivity(
    challengeId: string,
    challengeProgressId: string,
    activityId: string,
    numberOfActivities: number,
    when: number,
    sentiments: any[],
    percentageCompleted: number
  ): void {
    if (!this.okToTrack()) return;
    trackSelfDescribingEvent(
      {
        event: {
          schema: this.schema,
          data: {
            category: Category.Challenge,
            action: ChallengeAction.AddedChallengeActivity,
            timestamp: Date.now(),
            challengeId,
            numberOfActivities,
            when,
            sentiments,
            activityId,
            challengeProgressId,
            percentageCompleted,
          },
        },
      } as SelfDescribingEvent<Record<string, unknown>> &
        CommonEventProperties<Record<string, unknown>>,
      [this.namespace]
    );
  }

  public trackEditChallengeActivity(
    challengeId: string,
    challengeProgressId: string,
    activityId: string,
    numberOfActivities: number,
    when: number,
    sentiments: any[],
    percentageCompleted: number
  ): void {
    if (!this.okToTrack()) return;
    trackSelfDescribingEvent(
      {
        event: {
          schema: this.schema,
          data: {
            category: Category.Challenge,
            action: ChallengeAction.EditedChallengeActivity,
            timestamp: Date.now(),
            challengeId,
            numberOfActivities,
            when,
            sentiments,
            activityId,
            challengeProgressId,
            percentageCompleted,
          },
        },
      } as SelfDescribingEvent<Record<string, unknown>> &
        CommonEventProperties<Record<string, unknown>>,
      [this.namespace]
    );
  }

  public trackRemoveChallengeActivity(
    challengeId: string,
    challengeProgressId: string,
    activityId: string,
    numberOfActivities: number,
    percentageCompleted: number
  ): void {
    if (!this.okToTrack()) return;
    trackSelfDescribingEvent(
      {
        event: {
          schema: this.schema,
          data: {
            category: Category.Challenge,
            action: ChallengeAction.RemovedChallengeActivity,
            timestamp: Date.now(),
            challengeId,
            numberOfActivities,
            activityId,
            challengeProgressId,
            percentageCompleted,
          },
        },
      } as SelfDescribingEvent<Record<string, unknown>> &
        CommonEventProperties<Record<string, unknown>>,
      [this.namespace]
    );
  }

  private convertToUSD(amount: number) {
    return amount * 0.01;
  }
}
