export const loginType = '[authz] login';
export const loginSuccessType = '[authz] login success';
export const loginFailureType = '[authz] login failure';

export const logoutType = '[authz] logout';
export const logoutSuccessType = '[authz] logout success';
export const logoutFailureType = '[authz] logout failure';

export const loadPermissionsType = '[authz] load permissions';
export const loadPermissionsSuccessType = '[authz] load permissions success';
export const loadPermissionsFailureType = '[authz] load permissions failure';

export const redirectType = '[authz] redirect';
export const redirectSuccessType = '[authz] redirect success';
export const redirectFailureType = '[authz] redirect failure';

export const reloadTokenType = '[authz] reload token';
export const reloadTokenSuccessType = '[authz] reload token success';
export const reloadTokenFailureType = '[authz] reload token failure';

export const loadMeType = '[authz] load me';
export const loadMeSuccessType = '[authz] load me success';
export const loadMeFailureType = '[authz] load me failure';

export const requestProvisionalCustomerType =
  '[authz] request provisional customer';
export const requestProvisionalCustomerSuccessType =
  '[authz] request provisional customer success';
export const requestProvisionalCustomerFailureType =
  '[authz] request provisional customer failure';
