import {
  EventEmitter,
  Inject,
  Injectable,
  OnDestroy,
  PLATFORM_ID,
} from '@angular/core';
import {
  Auth,
  UserCredential,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  TwitterAuthProvider,
  GithubAuthProvider,
  User,
  signInAnonymously,
  signInWithEmailAndPassword,
  signInWithPopup,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  authState,
  onIdTokenChanged,
  updateProfile,
  UserInfo,
  IdTokenResult,
  updatePassword,
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import {
  AuthzConfig,
  AUTHZ_CONFIG,
  EventsService,
  TraceService,
  SpanTypes,
  APP_CONFIG,
  AppConfig,
  STRIPE_ENABLED,
  SIDKIK_TENANT,
  SIDKIK_AUTH_TENANT,
  ClientCryptoService,
  EntityType,
} from '@sidkik/global';
import { NotificationService } from '@sidkik/ui';

import {
  BehaviorSubject,
  firstValueFrom,
  forkJoin,
  from,
  lastValueFrom,
  Observable,
  of,
  Subscription,
  timer,
} from 'rxjs';
import { exhaustMap, filter, map, take, tap } from 'rxjs/operators';
import { AuthzFacade } from '../+state/authz.facade';
import { APIService } from './api.service';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { AutoDestroy } from '@sidkik/shared';
import {
  DbService,
  StorageFactory,
  ProvisionalCustomerProperties,
  ProvisionalCustomer,
} from '@sidkik/db';

export const facebookAuthProvider = new FacebookAuthProvider();
export const googleAuthProvider = new GoogleAuthProvider();
export const appleAuthProvider = new OAuthProvider('apple.com');
export const twitterAuthProvider = new TwitterAuthProvider();
export const githubAuthProvider = new GithubAuthProvider();
export const microsoftAuthProvider = new OAuthProvider('microsoft.com');
export const yahooAuthProvider = new OAuthProvider('yahoo.com');

export enum AuthProvider {
  ALL = 'all',
  ANONYMOUS = 'anonymous',
  EmailAndPassword = 'firebase',
  Google = 'google',
  Apple = 'apple',
  Facebook = 'facebook',
  Twitter = 'twitter',
  Github = 'github',
  Microsoft = 'microsoft',
  Yahoo = 'yahoo',
  PhoneNumber = 'phoneNumber',
}

@Injectable({
  providedIn: 'root',
})
export class AuthProcessService implements OnDestroy {
  onSuccessEmitter: EventEmitter<any> = new EventEmitter<any>();
  onErrorEmitter: EventEmitter<any> = new EventEmitter<any>();

  user$!: Observable<User | null>;
  @AutoDestroy()
  loaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  user!: User | null;
  private password: string | undefined;
  private initialLoadComplete = false;

  private redirectUrl: string | undefined = undefined;

  messageOnAuthSuccess!: string;
  messageOnAuthError!: string;

  // Legacy field that is set to true after sign up.
  // Value is lost in case of reload. The idea here is to know if we just sent a verification email.
  emailConfirmationSent!: boolean;
  // Legacy filed that contain the mail to confirm. Same lifecycle than emailConfirmationSent.
  emailToConfirm!: string;

  authChangeSubscription!: Subscription;

  numberCIDChecks = 0;
  numberAIDChecks = 0;

  constructor(
    @Inject(AUTHZ_CONFIG) readonly authzConfig: AuthzConfig,
    @Inject(APP_CONFIG) readonly appConfig: AppConfig,
    @Inject('ADMIN_TOOL') readonly isAdminTool: boolean,
    @Inject(STRIPE_ENABLED) readonly stripeEnabled: boolean,
    public auth: Auth,
    private authzFacade: AuthzFacade,
    private router: Router,
    private notificationService: NotificationService,
    private eventsService: EventsService,
    private apiService: APIService,
    private traceService: TraceService,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: Document,
    @Inject(SIDKIK_TENANT) private tenantId: string,
    @Inject(SIDKIK_AUTH_TENANT) private authTenantId: string,
    private dbService: DbService
  ) {
    if (isPlatformServer(this.platformId)) return;
    this.listenToUserEvents();
  }

  ngOnDestroy(): void {
    if (this.authChangeSubscription) this.authChangeSubscription.unsubscribe();
  }

  setRedirectUrl(url: string) {
    this.redirectUrl = url;
  }

  getRedirectUrl() {
    return this.redirectUrl;
  }

  async refreshToken() {
    if (this.user) {
      let token;
      try {
        this.traceService.startSpan(SpanTypes.firebaseGetToken);
        token = await this.user.getIdTokenResult(true);
        this.traceService.endSpan(SpanTypes.firebaseGetToken);
      } catch (err: any) {
        this.traceService.endSpan(SpanTypes.firebaseGetToken, err);
        throw err;
      }

      this.authzFacade.tokenRefreshed(this.user, token);
    }
    return;
  }

  // Define the method to check for claims
  private startCheckingForCustomerClaim(user: User) {
    // start a timer to wait for the custom claim to be added
    const getTokenWithCustomerId = timer(1000, 1000).subscribe({
      next: async () => {
        if (this.numberCIDChecks > 60) {
          getTokenWithCustomerId.unsubscribe();
          this.notificationService.showError(
            'Unable to verify your account',
            'Logging you out'
          );
          setTimeout(() => {
            this.signOut();
          }, 2000);
          throw new Error('Exceeded number of checks for cid');
        }
        if (this.user) {
          let token;
          try {
            this.traceService.startSpan(SpanTypes.firebaseGetToken);
            token = await this.user.getIdTokenResult(true);
            this.traceService.endSpan(SpanTypes.firebaseGetToken);
          } catch (err: any) {
            this.traceService.endSpan(SpanTypes.firebaseGetToken, err);
            throw err;
          }
          const { claims } = token;
          // if the customer id is found, stop looking
          if (claims['c_id']) {
            logger.info(
              'authz:auth-process.service.ts:listenToUserEvents: login success',
              'found customer claim',
              claims['c_id']
            );
            this.eventsService.setUser(
              claims['c_id'].toString(),
              user.email ?? 'no-email'
            );
            this.eventsService.trackLogin();
            this.authzFacade.loginSuccess(user, token, false);
            if (this.password) {
              this.authzFacade.processAuthentication(this.password);
              this.password = undefined;
            }
            this.authzFacade.loggedIn$
              .pipe(
                filter((loggedIn) => loggedIn === true),
                take(1)
              )
              .subscribe({
                next: () => {
                  this.authzFacade.loadMe();
                  if (this.redirectUrl) {
                    this.router.navigate([this.redirectUrl]);
                    this.redirectUrl = undefined;
                  }
                },
              });
            getTokenWithCustomerId.unsubscribe();
          }
          this.numberCIDChecks++;
        }
      },
    });
  }

  listenToUserEvents() {
    this.user$ = authState(this.auth);

    timer(1800000, 1800000).subscribe({
      next: async () => {
        await this.refreshToken();
      },
    });

    onIdTokenChanged(this.auth, (user) => {
      if (user !== null) {
        this.getTokenWithUser(user)
          .pipe(
            filter((token) => token !== null && token !== undefined),
            take(1)
          )
          .subscribe((token) => {
            this.user = user;
            if (token) this.authzFacade.tokenRefreshed(user, token);
          });
      }
    });

    authState(this.auth)
      .pipe(
        tap((user) => {
          if (user !== null) {
            this.user = user;
          }
          this.loaded$.next(true);
        }),
        filter((user) => user !== null),
        exhaustMap((user: User | null) =>
          forkJoin([of(user), this.getTokenWithUser(user)])
        )
      )
      .subscribe({
        next: ([user, token]) => {
          if (user && token) {
            // check if token has c_id - this is the customer id that allows access to the customer information documents
            if (token) {
              const { claims } = token;

              if (this.isAdminTool && !claims['r'] && !claims['l']) {
                this.notificationService.showError('Not authorized', '');
                this.signOut();
                return;
              }
              if (!claims['c_id']) {
                if (!claims['r']) {
                  this.authzFacade.provisionalCustomerRequested$
                    .pipe(
                      filter((requested) => requested === true),
                      take(1)
                    )
                    .subscribe({
                      next: () => {
                        logger.info(
                          'authz:auth-process.service.ts:listenToUserEvents: provisional customer requested',
                          'starting check for customer claim'
                        );
                        this.startCheckingForCustomerClaim(user);
                      },
                    });
                }
              }
            }

            this.authzFacade.loginSuccess(
              user,
              token,
              !this.initialLoadComplete
            );
            if (token) {
              const { claims } = token;
              if (claims['c_id']) {
                logger.info(
                  'authz:auth-process.service.ts:listenToUserEvents: login success',
                  'found customer claim',
                  claims['c_id']
                );
                const customerId = claims['c_id'].toString();
                this.authzFacade.loadMe();
                this.eventsService.setUser(
                  customerId,
                  user.email ?? 'no-email'
                );
                this.eventsService.trackLogin();
                if (this.password) {
                  this.authzFacade.processAuthentication(this.password);
                  this.password = undefined;
                }
              }
            }
            if (this.redirectUrl) {
              this.authzFacade.loggedIn$
                .pipe(
                  filter((loggedIn) => loggedIn === true),
                  take(1)
                )
                .subscribe({
                  next: () => {
                    this.router.navigate([this.redirectUrl]);
                    this.redirectUrl = undefined;
                  },
                });
            }
          }

          // // flags after first load
          // this.initialLoadComplete = true; // fixes https://github.com/sidkik/ep-app/issues/13
        },
      });
  }

  getTokenWithUser(user: User | null): Observable<IdTokenResult | undefined> {
    if (user !== null) {
      return from(user.getIdTokenResult());
    } else {
      return of(undefined);
    }
  }

  /**
   * Change the password
   *
   * @param email - the email to reset
   */
  public async confirmPasswordReset(
    oobCode: string,
    newPassword: string
  ): Promise<void> {
    try {
      this.traceService.startSpan(SpanTypes.firebaseConfirmPasswordReset);
      await confirmPasswordReset(this.auth, oobCode, newPassword);
      this.traceService.endSpan(SpanTypes.firebaseConfirmPasswordReset);
    } catch (err: any) {
      this.traceService.endSpan(SpanTypes.firebaseConfirmPasswordReset, err);
      throw err;
    }
    this.notificationService.showInfo(
      'Password changed',
      'You can now login with your new password.'
    );

    this.router.navigate(['../login']);
  }

  /**
   * Reset the password of the ngx-auth-firebaseui-user via email
   *
   * @param email - the email to reset
   */
  public async resetPassword(email: string): Promise<void> {
    // await sendPasswordResetEmail(this.auth, email);
    try {
      this.traceService.startSpan(SpanTypes.firebaseResetPassword, {
        targetEmail: email,
        targetTenant: this.appConfig.firebase.projectId,
        hostUrl: this.document.location.hostname,
      });
      await firstValueFrom(this.apiService.resetPassword(email));
      this.traceService.endSpan(SpanTypes.firebaseResetPassword);
    } catch (err: any) {
      this.traceService.endSpan(SpanTypes.firebaseResetPassword, err);
    }

    this.notificationService.showInfo(
      'Password request sent',
      'You should receive an email shortly. Email will be sent from sidkiknotifications.com'
    );
  }

  /**
   * General sign in mechanism to authenticate the users with a firebase project
   * using a traditional way, via username and password or by using an authentication provider
   * like google, facebook, twitter and github
   *
   * @param provider - the provider to authenticate with (google, facebook, twitter, github)
   * @param credentials optional email and password
   */
  public async signInWith(provider: AuthProvider, credentials?: any) {
    // block sign in if not admin tool and stripe not enabled
    if (!this.isAdminTool) {
      if (!this.stripeEnabled) {
        this.notificationService.showError(
          'Sign in disabled',
          'Application configuration does not allow sign in'
        );
        return;
      }
    }
    let signInResult: UserCredential | any;
    try {
      this.traceService.startSpan(SpanTypes.firebaseSigninWith);

      switch (provider) {
        case AuthProvider.ANONYMOUS:
          signInResult = (await signInAnonymously(this.auth)) as UserCredential;
          break;

        case AuthProvider.EmailAndPassword:
          signInResult = (await signInWithEmailAndPassword(
            this.auth,
            credentials.email,
            credentials.password
          )) as UserCredential;
          this.password = credentials.password;
          break;

        case AuthProvider.Google:
          signInResult = (await signInWithPopup(
            this.auth,
            googleAuthProvider
          )) as UserCredential;
          break;

        case AuthProvider.Apple:
          signInResult = (await signInWithPopup(
            this.auth,
            appleAuthProvider
          )) as UserCredential;
          break;

        case AuthProvider.Facebook:
          signInResult = (await signInWithPopup(
            this.auth,
            facebookAuthProvider
          )) as UserCredential;
          break;

        case AuthProvider.Twitter:
          signInResult = (await signInWithPopup(
            this.auth,
            twitterAuthProvider
          )) as UserCredential;
          break;

        case AuthProvider.Github:
          signInResult = (await signInWithPopup(
            this.auth,
            githubAuthProvider
          )) as UserCredential;
          break;

        case AuthProvider.Microsoft:
          signInResult = (await signInWithPopup(
            this.auth,
            microsoftAuthProvider
          )) as UserCredential;
          break;

        case AuthProvider.Yahoo:
          signInResult = (await signInWithPopup(
            this.auth,
            yahooAuthProvider
          )) as UserCredential;
          break;

        case AuthProvider.PhoneNumber:
          // coming soon - see feature/sms branch
          break;

        default:
          throw new Error(` is not available as auth provider`);
      }

      this.traceService.endSpan(SpanTypes.firebaseSigninWith);
    } catch (err: any) {
      this.traceService.endSpan(SpanTypes.firebaseSigninWith, err);
      throw err;
    }
  }

  /**
   * Sign up new users via email and password.
   * After that the ngx-auth-firebaseui-user should verify and confirm an email sent via the firebase
   *
   * @param displayName - the displayName if the new ngx-auth-firebaseui-user
   * @param credentials email and password
   * @returns -
   */
  public async signUp(displayName: string, credentials: any, source?: string) {
    // block sign in if not admin tool and stripe not enabled
    if (!this.isAdminTool) {
      if (!this.stripeEnabled) {
        this.notificationService.showError(
          'Sign in disabled',
          'Application configuration does not allow sign in'
        );
        return;
      }
    }
    let userCredential;
    try {
      this.traceService.startSpan(SpanTypes.firebaseSignup);
      userCredential = await createUserWithEmailAndPassword(
        this.auth,
        credentials.email,
        credentials.password
      );
    } catch (error: any) {
      this.traceService.endSpan(SpanTypes.firebaseSignup, error);
      throw error;
    }

    try {
      this.traceService.startChildSpan(
        SpanTypes.firebaseSigninWith,
        SpanTypes.firebaseSignup
      );
      await this.signInWith(AuthProvider.EmailAndPassword, {
        email: credentials.email,
        password: credentials.password,
      });
      this.traceService.endSpan(SpanTypes.firebaseSigninWith);
    } catch (error: any) {
      this.traceService.endSpan(SpanTypes.firebaseSigninWith, error);
      throw error;
    }

    const user = userCredential.user;
    try {
      this.traceService.startChildSpan(
        SpanTypes.firebaseUpdateProfileName,
        SpanTypes.firebaseSignup
      );
      await this.updateProfileName(displayName);
    } catch (error: any) {
      this.traceService.endSpan(SpanTypes.firebaseUpdateProfileName, error);
      throw error;
    }
    this.traceService.endSpan(SpanTypes.firebaseSignup);

    // create a provisional customer
    // this will drop a document in the /admin/all/requests/all/provisional-customer path
    // will be verified and then the customer will be created
    try {
      this.traceService.startSpan(SpanTypes.firebaseCreateProvisionalCustomer);
      const provisionalCustomer = StorageFactory.getFactory(
        ProvisionalCustomer,
        {
          id: user.uid,
          data: {
            email: credentials.email,
            displayName,
            provider: 'firebase',
            tenant: this.tenantId,
            authenticationTenant: this.authTenantId,
            authenticationId: user.uid,
          },
        } as ProvisionalCustomerProperties
      );
      provisionalCustomer.addTrace(
        this.traceService.getDocumentTrace(
          SpanTypes.firebaseCreateProvisionalCustomer
        )
      );
      this.authzFacade.requestProvisionalCustomer(provisionalCustomer);
    } catch (error: any) {
      this.traceService.endSpan(
        SpanTypes.firebaseCreateProvisionalCustomer,
        error
      );
      throw error;
    }

    // if (this.config.enableEmailVerification) {
    //   await sendEmailVerification(user);
    // }

    if (source && source === 'affiliate') {
      // start a check for the affiliate id claim
      const getTokenWithAffiliateId = timer(4000, 3000).subscribe({
        next: async () => {
          if (this.numberCIDChecks > 20) {
            getTokenWithAffiliateId.unsubscribe();
            this.notificationService.showError(
              'Unable to verify your account',
              'Logging you out'
            );
            setTimeout(() => {
              this.signOut();
            }, 2000);
            throw new Error('Exceeded number of checks for aid');
          }
          if (this.user) {
            let token;
            try {
              this.traceService.startSpan(SpanTypes.firebaseGetToken);
              token = await this.user.getIdTokenResult(true);
              this.traceService.endSpan(SpanTypes.firebaseGetToken);
            } catch (err: any) {
              this.traceService.endSpan(SpanTypes.firebaseGetToken, err);
              throw err;
            }
            const { claims } = token;
            // if the affiliate id is found, stop looking
            if (claims['a_id']) {
              this.authzFacade.loginSuccess(user, token, false);
              getTokenWithAffiliateId.unsubscribe();
            }
          }
          this.numberAIDChecks++;
        },
      });

      // redirect to a holding room
      this.router.navigate(['affiliate', 'holding']);
    }

    // Legacy fields
    this.emailConfirmationSent = true;
    this.emailToConfirm = credentials.email;
  }

  async sendNewVerificationEmail(): Promise<void | never> {
    // if (!this.user) {
    //   return Promise.reject(new Error('No signed in user'));
    // }
    // return sendEmailVerification(this.user);
  }

  async signOut() {
    try {
      this.traceService.startSpan(SpanTypes.firebaseSignout);
      await this.auth.signOut();
      this.traceService.endSpan(SpanTypes.firebaseSignout);
      this.user = null;
      this.authzFacade.logoutSuccess();
    } catch (err: any) {
      this.traceService.endSpan(SpanTypes.firebaseSignout, err);
      this.notificationService.showError('Error signing out', err.message);
    }
  }

  /**
   * Update the profile (name + photo url) of the authenticated ngx-auth-firebaseui-user in the
   * firebase authentication feature (not in firestore)
   *
   * @param name - the new name of the authenticated ngx-auth-firebaseui-user
   * @param photoURL - the new photo url of the authenticated ngx-auth-firebaseui-user
   * @returns -
   */
  public async updateProfileName(displayName: string): Promise<void> {
    if (!this.auth.currentUser) {
      this.notificationService.showError(
        'Unknown Issue',
        'current profile not available'
      );
    }
    if (this.auth.currentUser) {
      try {
        this.traceService.startSpan(SpanTypes.firebaseUpdateProfileName);
        await updateProfile(this.auth.currentUser, {
          displayName,
        });
        this.traceService.endSpan(SpanTypes.firebaseUpdateProfileName);
        return;
      } catch (err: any) {
        this.traceService.endSpan(SpanTypes.firebaseUpdateProfileName, err);
        throw err;
      }
    }
    return;
  }

  public async updateProfilePicture(photoURL: string): Promise<void> {
    if (!this.auth.currentUser) {
      this.notificationService.showError(
        'Unknown Issue',
        'current profile not available'
      );
    }
    if (this.auth.currentUser) {
      try {
        this.traceService.startSpan(SpanTypes.firebaseUpdateProfilePicture);
        await updateProfile(this.auth.currentUser, {
          photoURL,
        });
        this.traceService.endSpan(SpanTypes.firebaseUpdateProfilePicture);
        return;
      } catch (err: any) {
        this.traceService.endSpan(SpanTypes.firebaseUpdateProfilePicture, err);
        throw err;
      }
    }
    return;
  }

  public async updatePassword(oldPassword: string, newPassword: string) {
    const user = this.auth.currentUser;

    if (!user) {
      this.notificationService.showError(
        'Unable to reset password',
        'No user identified'
      );
    }

    if (!user?.email) {
      this.notificationService.showError(
        'Unable to reset password',
        'No email on account'
      );
      return;
    }

    try {
      this.traceService.startSpan(SpanTypes.firebaseSigninWith);
      const credential = await signInWithEmailAndPassword(
        this.auth,
        user?.email as unknown as string,
        oldPassword
      );
      this.traceService.endSpan(SpanTypes.firebaseSigninWith);
    } catch (err: any) {
      this.traceService.endSpan(SpanTypes.firebaseSigninWith, err);
      this.notificationService.showError(
        'Unable to reset password',
        err.message
      );
      return;
    }

    try {
      this.traceService.startSpan(SpanTypes.firebaseUpdatePassword);
      await updatePassword(user, newPassword);
      this.traceService.endSpan(SpanTypes.firebaseUpdatePassword);
    } catch (err: any) {
      this.traceService.endSpan(SpanTypes.firebaseUpdatePassword, err);
      this.notificationService.showError(
        'Unable to reset password',
        err.message
      );
      return;
    }
    this.notificationService.showInfo(
      'Password reset',
      'Please use your new password on your next login'
    );
  }

  public parseUserInfo(user: User): UserInfo {
    return {
      uid: user.uid,
      displayName: user.displayName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      photoURL: user.photoURL,
      providerId:
        user.providerData.length > 0 ? user.providerData[0].providerId : '',
    };
  }

  public getUserPhotoUrl(): Observable<string | null> {
    return this.user$.pipe(
      map((user: User | null) => {
        if (!user) {
          return null;
        } else {
          return user.photoURL;
          // } else if (user.emailVerified) {
          //   return this.getPhotoPath(Accounts.CHECK);
          // } else if (user.isAnonymous) {
          //   return this.getPhotoPath(Accounts.OFF);
          // } else {
          //   return this.getPhotoPath(Accounts.NONE);
        }
      })
    );
  }

  public getPhotoPath(image: string): string {
    return `${this.tenantId}/assets/user/${image}.svg`;
  }

  public signInWithPhoneNumber() {
    // todo: 3.1.18
  }

  // Refresh user info. Can be useful for instance to get latest status regarding email verification.
  reloadUserInfo() {
    return this.user$
      .pipe(take(1))
      .subscribe((user: User | null) => user && user.reload());
  }
}
