import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  Cart,
  CartItemProperties,
  CouponProperties,
  CustomerProperties,
  OrderRequest,
  OrderRequestData,
  OrderRequestProperties,
  PaymentMethod,
  PromoCodeProperties,
  StorageFactory,
} from '@sidkik/db';
import {
  AppConfig,
  APP_CONFIG,
  EventsService,
  IntegrationStatus,
  TraceService,
  SpanTypes,
  ShopError,
  CookieService,
  CookieAffiliateCode,
  MOCK_ENABLED,
} from '@sidkik/global';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest,
  filter,
  firstValueFrom,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { CartFacade } from '../../../+state/cart/cart.facade';
import {
  SetupIntentResult,
  StripeError,
  PaymentMethod as StripePaymentMethod,
} from '@stripe/stripe-js';
import { RequestFacade } from '../../../+state/request/request.facade';
import { ShopFacade } from '../../../+state/shop.facade';
import { StripeFactoryService, StripeInstance } from 'ngx-stripe';
import {
  ButtonState,
  CaptureService,
  ErrorService,
  NotificationService,
} from '@sidkik/ui';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import {
  ref,
  uploadBytesResumable,
  Storage,
  getDownloadURL,
} from '@angular/fire/storage';
import { AutoDestroy, BaseDestroyComponent } from '@sidkik/shared';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'sidkik-checkout-steps',
  templateUrl: './checkout-steps.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutStepsComponent
  extends BaseDestroyComponent
  implements OnInit, OnDestroy
{
  cartItems$!: Observable<Partial<CartItemProperties[]>>;
  coupon$!: Observable<CouponProperties | undefined>;
  promoCode$!: Observable<PromoCodeProperties | undefined>;
  me$!: Observable<CustomerProperties>;
  subTotal$!: Observable<number>;
  total$!: Observable<number>;
  discounts$!: Observable<number>;
  requiresFuturePaymentMethod$!: Observable<boolean>;
  termsRequired$!: Observable<boolean>;
  terms$!: Observable<string | undefined>;

  attemptPaymentSubscription!: Subscription;

  @AutoDestroy()
  processingState$: BehaviorSubject<ButtonState> =
    new BehaviorSubject<ButtonState>(ButtonState.ready);
  @AutoDestroy()
  orderIssue$: BehaviorSubject<string | undefined> = new BehaviorSubject<
    string | undefined
  >(undefined);

  pastAttempts: string[] = [];
  stripe!: StripeInstance;

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    @Inject(MOCK_ENABLED) private mockEnabled: boolean,
    private cartFacade: CartFacade,
    private shopFacade: ShopFacade,
    private requestFacade: RequestFacade,
    private eventsService: EventsService,
    private traceService: TraceService,
    private stripeService: StripeFactoryService,
    private errorService: ErrorService,
    private notificationService: NotificationService,
    private router: Router,
    private cookieService: CookieService,
    private http: HttpClient,
    private captureService: CaptureService,
    @Inject(DOCUMENT) private document: Document,
    private storage: Storage,
    private titleService: Title
  ) {
    super();
    this.stripe = this.stripeService.create(appConfig.stripe?.pk, {
      stripeAccount: appConfig.stripe?.acct,
    });
  }

  ngOnInit(): void {
    this.cartItems$ = this.cartFacade.allCartItemsWithDiscounts$;
    this.coupon$ = this.cartFacade.coupon$;
    this.promoCode$ = this.cartFacade.promoCode$;
    this.me$ = this.shopFacade.me$;
    this.subTotal$ = this.cartFacade.subTotal$;
    this.total$ = this.cartFacade.total$;
    this.discounts$ = this.cartFacade.discounts$;
    this.requiresFuturePaymentMethod$ =
      this.cartFacade.requiresFuturePaymentMethod$;
    this.termsRequired$ = this.cartFacade.termsRequired$;
    this.terms$ = this.cartFacade.terms$;

    this.titleService.setTitle('Checkout');

    this.requestFacade.selectedRequest$
      .pipe(
        tap((r) =>
          logger.debug('shop:checkout:attemptPayment', 'selectedRequest', r)
        ),
        filter((r) => r !== undefined && r !== null),
        // dedup
        filter((r) => {
          if (
            [IntegrationStatus.complete, IntegrationStatus.error].includes(
              r?.integrations.state.status ?? IntegrationStatus.reset
            )
          ) {
            logger.debug(
              'shop:checkout:attemptPayment',
              'already completed',
              r
            );
            return true; // already completed
          }
          const attempt = r?.data.setupIntentId as string;
          const foundAttempt = this.pastAttempts.find((pa) => pa === attempt);
          logger.debug(
            'shop:checkout:attemptPayment',
            'attempt',
            attempt,
            foundAttempt
          );
          return !foundAttempt;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(async (r) => {
        logger.trace(
          'shop:checkout:attemptPayment',
          'status',
          r?.integrations.state.status
        );
        switch (r?.integrations.state.status) {
          case IntegrationStatus.pending: {
            const attempt = r.data.setupIntentId as string;
            this.pastAttempts.push(attempt);
            this.confirmSetup(r);
            break;
          }
          case IntegrationStatus.error:
            this.paymentErrored(r);
            break;
          case IntegrationStatus.complete:
            this.paymentCompleted(r);
            break;
        }
      });

    this.eventsService.trackScreenView('checkout', 'shop/checkout');
    this.cartItems$
      .pipe(
        filter((items) => items && items.length > 0),
        takeUntil(this.destroy$)
      )
      .subscribe((items) =>
        this.eventsService.beginCheckout(items as CartItemProperties[])
      );
  }

  async paymentCompleted(request: OrderRequestProperties) {
    this.processingState$.next(ButtonState.succeeded);
    this.cartItems$
      .pipe(
        filter((items) => items && items.length > 0),
        take(1)
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe((items) => {
        this.requestFacade.cleanupRequest(request);
        this.cartFacade.cleanupCart(items as unknown as CartItemProperties[]);
      });
    this.total$
      .pipe(
        filter((total) => total !== undefined && total !== null),
        take(1)
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe((total) => {
        this.eventsService.orderComplete(request, total);
        this.eventsService.purchase(
          request.data.cart.data.items,
          0, // TODO: update with total since this is no longer valid piResult?.paymentIntent?.amount ?? 0,
          request.data.cart.data.promoCode?.data?.code
        );
      });
    this.traceService.endSpan(SpanTypes.shopPayOrder);
    this.traceService.endSpan(SpanTypes.shopProcessOrder);

    this.notificationService.showInfo(
      'Order Success',
      'Loading your purchase now. Will be a few moments'
    );
    this.shopFacade.monitorMeUpdate();
    this.router.navigate(['my-activities']);
    return;
  }

  async paymentErrored(request: OrderRequestProperties) {
    logger.error(
      'shop:checkout:attemptPayment',
      'error',
      request.integrations.state.message
    );
    this.processingState$.next(ButtonState.errored);
    const err = new ShopError(
      request.integrations.state.message ?? 'unknown issue',
      'Order Issue',
      request.integrations.state.message ?? 'unknown issue'
    );
    this.orderIssue$.next(request.integrations.state.message);
    this.errorService.handleError(err);
    this.traceService.endSpan(SpanTypes.shopProcessOrder, err);
    this.total$
      .pipe(
        filter((total) => total !== undefined && total !== null),
        take(1)
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe((total) => {
        this.eventsService.orderIssue(request, total);
      });
    return;
  }

  async confirmSetup(request: OrderRequestProperties) {
    logger.trace('shop:checkout:attemptPayment', 'request', request);
    if (request.data.isFree && !request.data.requiresFuturePaymentMethod) {
      logger.trace(
        'shop:checkout:attemptPayment',
        'is free with no future payment method required'
      );

      this.eventsService.completePaymentInfo('free');
      this.processingState$.next(ButtonState.succeeded);
      this.cartItems$
        .pipe(
          filter((items) => items && items.length > 0),
          take(1)
        )
        // eslint-disable-next-line rxjs-angular/prefer-takeuntil
        .subscribe((items) => {
          this.requestFacade.cleanupRequest(request);
          this.cartFacade.cleanupCart(items as unknown as CartItemProperties[]);
        });
      this.traceService.endSpan(SpanTypes.shopProcessOrder);
      this.notificationService.showInfo(
        'Order Success',
        'Loading your purchase now. Will be a few moments'
      );
      this.shopFacade.monitorMeUpdate();
      this.router.navigate(['my-activities']);
      return;
    }
    logger.trace(
      'shop:checkout:attemptPayment',
      'starting payment with intent/secret',
      request.data.paymentIntentSecret,
      request.data.setupIntentSecret
    );
    if (
      request.data.setupIntentSecret &&
      request.data.setupIntentSecret !== ''
    ) {
      let siResult: SetupIntentResult | undefined;
      this.traceService.startChildSpan(
        SpanTypes.shopPayOrder,
        SpanTypes.shopProcessOrder
      );

      // not able to get msw to intercept stripe api calls
      // TODO: check occasionally to see if we can move fully back to msw
      if (
        this.mockEnabled &&
        request.data.paymentIntentSecret?.startsWith('mock_')
      ) {
        siResult = await firstValueFrom(
          this.http.post<SetupIntentResult | undefined>(
            `/v1/payment_intents/${request.data.paymentIntentId}/confirm`,
            {}
          )
        );
      } else {
        if (
          request.data.setupIntentSecret &&
          request.data.setupIntentSecret !== ''
        ) {
          try {
            siResult = await firstValueFrom(
              this.stripe?.confirmSetup({
                clientSecret: request.data.setupIntentSecret,
                confirmParams: {},
                redirect: 'if_required',
              })
            );
          } catch (err) {
            siResult = { error: err as StripeError };
          }
          logger.trace('shop:checkout:attemptPayment', 'siResult', siResult);
          // need to wait for payment to come through unless it is already "complete"
        }
      }
      if (siResult?.error) {
        this.orderIssue$.next(siResult?.error.message);
        const err = new ShopError(
          siResult?.error.message ?? 'unknown issue',
          'Order Issue',
          siResult?.error.message ?? 'unknown issue'
        );
        this.errorService.handleError(err);
        this.processingState$.next(ButtonState.errored);
        this.traceService.endSpan(SpanTypes.shopPayOrder, err);
        this.traceService.endSpan(SpanTypes.shopProcessOrder);
        return;
      }

      this.eventsService.completePaymentInfo(
        request.data.paymentMethod?.data.brand ?? 'unknown'
      );

      // wait for payment to come through on the stripe side webhook
      logger.trace(
        'shop:checkout:attemptPayment',
        'waiting for complete',
        request,
        siResult
      );
      return this.shopFacade.requestMonitorForFinally(
        request,
        request.data.customer.id
      );
    }
    logger.error(
      'shop:checkout:attemptPayment',
      'no payment intent secret',
      'nothing processed'
    );
    this.notificationService.showError(
      'Order Issue',
      'Unable to process order due to internal processing error'
    );
    this.processingState$.next(ButtonState.ready);
  }

  clearIssue() {
    this.orderIssue$.next(undefined);
  }

  async waitForFiveSeconds(): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, 5000));
  }

  async uploadOrderSnapshot(
    requestId: string,
    userId: string,
    capturePngBlob: Blob | null
  ): Promise<string> {
    if (!capturePngBlob) {
      return '';
    }
    const tenantId = this.appConfig.firebase.tenantId;
    const uploadKey = `${tenantId}/assets/${userId}/${requestId}.png`;

    const task = uploadBytesResumable(
      ref(this.storage, uploadKey),
      capturePngBlob,
      { contentType: 'image/png' }
    );

    logger.trace(
      'shop:checkout:uploadOrderSnapshot',
      'uploading order snapshot',
      uploadKey
    );

    try {
      this.traceService.startSpan(SpanTypes.firebaseUploadOrderSnapshot);

      // Add timeout check
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => {
          if (task.snapshot.bytesTransferred === 0) {
            logger.error(
              'shop:checkout:uploadOrderSnapshot',
              'upload timeout no bytes transferred after 10 seconds'
            );
            reject(
              new Error(
                'Upload timeout - no bytes transferred after 10 seconds'
              )
            );
          }
        }, 10000);
      });

      task.on('state_changed', (snapshot) => {
        logger.trace(
          'shop:checkout:uploadOrderSnapshot',
          'state changed',
          snapshot
        );
      });

      // Race between the upload and the timeout
      await Promise.race([task, timeoutPromise]);

      logger.trace('shop:checkout:uploadOrderSnapshot', 'task completed', task);
      this.traceService.endSpan(SpanTypes.firebaseUploadOrderSnapshot);
    } catch (err: any) {
      this.traceService.endSpan(SpanTypes.firebaseUploadOrderSnapshot, err);
      logger.error(
        'shop:checkout:uploadOrderSnapshot',
        'error',
        'unable to upload order snapshot',
        err
      );
      return '';
    }
    this.traceService.startSpan(SpanTypes.firebaseGetDownloadUrl);
    const url = await getDownloadURL(ref(this.storage, uploadKey));
    this.traceService.endSpan(SpanTypes.firebaseGetDownloadUrl);
    return url;
  }

  handleOrderRequest(pm?: StripePaymentMethod) {
    combineLatest([
      this.cartItems$,
      this.me$,
      this.coupon$,
      this.promoCode$,
      this.total$,
      this.termsRequired$,
      this.requiresFuturePaymentMethod$,
    ])
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(
        async ([
          items,
          me,
          coupon,
          promoCode,
          total,
          termsRequired,
          requiresFuturePaymentMethod,
        ]) => {
          this.traceService.startSpan(SpanTypes.shopProcessOrder);
          this.traceService.startChildSpan(
            SpanTypes.shopStoreOrderRequest,
            SpanTypes.shopProcessOrder
          );
          const trace = this.traceService.getDocumentTrace(
            SpanTypes.shopStoreOrderRequest
          );
          const cart = StorageFactory.getFactory(Cart, { data: { items } });
          const affiliateId = this.cookieService.get(CookieAffiliateCode);
          const orderRequestData: OrderRequestData = {
            cart,
            isFree: total === 0,
            customer: me,
            coupon,
            promotionCode: promoCode,
            requiresFuturePaymentMethod: requiresFuturePaymentMethod,
          };

          // add the affiliate id if it exists
          if (affiliateId) {
            orderRequestData.affiliateId = affiliateId;
          }

          const orderRequest = StorageFactory.getFactory(OrderRequest, {
            data: orderRequestData,
          });

          try {
            logger.info(
              'shop:checkout:handleOrderRequest',
              'order summary begin capture',
              orderRequest.id
            );

            // upload the snapshot to the server and get the url
            // capture the order summary
            const captureOrderSummaryPngBlob =
              await this.captureService.captureElementAsPngBlob(
                this.document.getElementById('order-summary') as HTMLElement
              );

            logger.info(
              'shop:checkout:handleOrderRequest',
              'order summary captured',
              orderRequest.id
            );

            // upload the snapshot to firebase
            const snapshotOrderSummaryUrl = await this.uploadOrderSnapshot(
              orderRequest.id,
              me.id,
              captureOrderSummaryPngBlob
            );

            if (
              snapshotOrderSummaryUrl !== null ||
              snapshotOrderSummaryUrl !== '' ||
              snapshotOrderSummaryUrl !== undefined
            ) {
              orderRequest.data.orderSnapshotUrl = snapshotOrderSummaryUrl;
            }
          } catch (err) {
            logger.error(
              'shop:checkout:handleOrderRequest',
              'error',
              'unable to capture order summary',
              err
            );
          }

          if (termsRequired) {
            try {
              logger.info(
                'shop:checkout:handleOrderRequest',
                'terms are required, capturing order terms',
                orderRequest.id
              );
              const el = this.document.getElementById(
                'order-terms'
              ) as HTMLElement;
              if (el) {
                const captureSubscriptionAgreementPngBlob =
                  await this.captureService.captureElementAsPngBlob(el);
                logger.info(
                  'shop:checkout:handleOrderRequest',
                  'order terms captured',
                  orderRequest.id
                );
                // upload the snapshot to firebase
                const snapshotOrderTermsUrl = await this.uploadOrderSnapshot(
                  orderRequest.id + '-terms',
                  me.id,
                  captureSubscriptionAgreementPngBlob
                );

                if (
                  snapshotOrderTermsUrl !== null ||
                  snapshotOrderTermsUrl !== '' ||
                  snapshotOrderTermsUrl !== undefined
                ) {
                  orderRequest.data.orderTermsUrl = snapshotOrderTermsUrl;
                }
              } else {
                logger.error(
                  'shop:checkout:handleOrderRequest',
                  'terms are required, but no terms element found',
                  orderRequest.id
                );
              }
            } catch (err) {
              logger.error(
                'shop:checkout:handleOrderRequest',
                'error',
                'unable to capture order terms',
                err
              );
            }
          }

          // check if payment method - if not, its free without terms (no future payments)
          if (pm && (total !== 0 || termsRequired)) {
            logger.info(
              'shop:checkout:handleOrderRequest',
              'payment method',
              'adding payment method to order request',
              orderRequest.id
            );
            const paymentMethod = StorageFactory.getFactory(PaymentMethod, {
              data: {
                name: 'one-time',
                expMonth: pm.card?.exp_month,
                expYear: pm.card?.exp_year,
                brand: pm.card?.brand,
                fingerprint: pm.card?.fingerprint,
                last4: pm.card?.last4,
                cvcCheck: pm.card?.checks,
                country: pm.card?.country,
              },
            });
            paymentMethod.setIntegrations({
              state: {
                status: IntegrationStatus.complete,
                updated: Date.now(),
              },
              stripe: { id: pm.id, updated: Date.now() },
            });
            orderRequest.addPaymentMethod(paymentMethod);
            this.eventsService.addPaymentInfo(items, total);
          }
          orderRequest.addTrace(trace);
          this.requestFacade.addRequest(orderRequest.toMinimalStorage());
        }
      );
  }

  removeCartItem(item: Partial<CartItemProperties>): void {
    this.cartFacade.deleteCartItem(item as CartItemProperties);
  }
}
