import { createReducer, on, Action } from '@ngrx/store';
import { authzStateKey, loadMeMaxRetries } from '@sidkik/global';
import { IdTokenResult } from '@angular/fire/auth';

import * as AuthzActions from './authz.actions';
import { User } from './authz.models';
import { CustomerProperties } from '@sidkik/db';

export const AUTHZ_FEATURE_KEY = authzStateKey;

export interface AuthzState {
  loggedIn: boolean;
  user: User | null;
  userId: string | null;
  affiliateId: string | null;
  idToken: IdTokenResult | null;
  jwt: any | null;
  initialRedirect: string | undefined;
  me: CustomerProperties | null;
  meLoaded: boolean;
  meLoading: boolean;
  permissions?: any;
  permissionsLoaded: boolean;
  permissionsError?: any;
  workerLoggedIn: boolean;
  mainLoggedIn: boolean;
  provisionalCustomerRequested: boolean;
}

export interface AuthzPartialState {
  readonly [AUTHZ_FEATURE_KEY]: AuthzState;
}

export const initialAuthzState: AuthzState = {
  loggedIn: false,
  user: null,
  userId: null,
  affiliateId: null,
  idToken: null,
  jwt: null,
  initialRedirect: undefined,
  me: null,
  meLoaded: false,
  meLoading: false,
  permissionsLoaded: false,
  workerLoggedIn: false,
  mainLoggedIn: false,
  provisionalCustomerRequested: false,
};

const reducer = createReducer(
  initialAuthzState,
  on(AuthzActions.requestProvisionalCustomerSuccess, (state) => ({
    ...state,
    provisionalCustomerRequested: true,
  })),
  on(AuthzActions.loginSuccess, (state, { user, idToken }) => ({
    ...state,
    loggedIn: true && state.workerLoggedIn,
    mainLoggedIn: true,
    user,
    userId: idToken.claims['c_id']?.toString() ?? '',
    affiliateId: idToken.claims['a_id']?.toString() ?? '',
    idToken,
  })),
  on(AuthzActions.workerLoggedIn, (state, { uid }) => {
    if (uid && uid !== '') {
      return {
        ...state,
        workerLoggedIn: true,
        loggedIn: true && state.mainLoggedIn,
      };
    }
    return {
      ...state,
      workerLoggedIn: false,
      loggedIn: false && state.mainLoggedIn,
    };
  }),
  on(AuthzActions.loadMeSuccess, (state, { me }) => ({
    ...state,
    me,
    meLoaded: true,
    meLoading: false,
  })),
  on(AuthzActions.loadMeFailure, (state, { attempt }) => {
    if (attempt ?? 0 >= loadMeMaxRetries) {
      return {
        ...state,
        meLoading: false,
      };
    }
    return {
      ...state,
    };
  }),
  on(AuthzActions.loadMe, (state) => ({
    ...state,
    meLoaded: false,
    meLoading: true,
  })),
  on(AuthzActions.reloadTokenSuccess, (state, { user, idToken }) => ({
    ...state,
    loggedIn: true && state.workerLoggedIn,
    mainLoggedIn: true,
    userId: idToken.claims['c_id']?.toString() ?? '',
    affiliateId: idToken.claims['a_id']?.toString() ?? '',
    user,
    idToken,
  })),
  on(AuthzActions.loginFailure, (state, { error }) => ({
    ...state,
    loggedIn: false,
    mainLoggedIn: false,
    error,
  })),
  on(AuthzActions.logoutSuccess, (state) => ({
    ...state,
    loggedIn: false,
    mainLoggedIn: false,
    meLoaded: false,
    permissionsLoaded: false,
    jwt: null,
    me: null,
    user: null,
    userId: null,
    affiliateId: null,
    idToken: null,
    workerLoggedIn: false,
  }))
);

export function authzReducer(state: AuthzState | undefined, action: Action) {
  return reducer(state, action);
}
